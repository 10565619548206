import gql from 'graphql-tag';
import { appointmentTypesFragments } from '../appointment-types/index';

export const carePlanFragments = {
  recommendedEvaluationData: gql`
    fragment RecommendedEvaluationData on RecommendedEvaluation {
      id
      appointmentType {
        id
        isClinical
      }
      appointmentSubType
      clientResponse
      status
      createdAt
    }
  `,
  recommendedTherapyData: gql`
    fragment RecommendedTherapyData on RecommendedTherapy {
      id
      appointmentType {
        id
        isClinical
      }
      appointmentSubType
      minRecommendedQuantity
      maxRecommendedQuantity
      requestedQuantity
      quantityPattern
      status
      updatedAt
    }
  `,
  carePlanData: gql`
    fragment CarePlanData on CarePlan {
      clientID
      clientName
      clinicID
      recommendedEvaluations {
        ...RecommendedEvaluationData
      }
      recommendedTherapies {
        ...RecommendedTherapyData
      }
    }
  `
};

export const GET_WAITLIST = gql`
  query GetWaitList(
    $limit: Int!
    $page: Int!
    $waitListFilter: FilterOptions
    $clientAvailability: [AvailabilityFilterInput!]
    $name: String
    $currentSearchIndex: Int
  ) {
    getCarePlansForWaitList(
      limit: $limit
      page: $page
      WaitListFilter: $waitListFilter
      clientAvailability: $clientAvailability
      name: $name
      currentSearchIndex: $currentSearchIndex
    ) {
      id
      CarePlan {
        eval_type__c
        id
        createdDate
        name
        SmartScheduling_status
        patient_account__c
        receiving__c
        recommended__c
        requested__c
        status__c
        therapy_type__c
        waitlisted_reason__c
        lastModifiedDate
        clinic_location__c
        axonReceivingHours
        DTReceivingUnits
        noteCount
        axonNotes {
          note
          actionBy
          actionAt
        }
        SalesForceMappedTherapy {
          id
          subTypeTitle
          subTypesalesforceTherapyTitle
          parentTypeId
          parentTypeTitle
          parentTypeEventId
        }
        clientCarePlans {
          id
          eval_type__c
          therapy_type__c
          requested__c
          recommended__c
          status__c
          receiving__c
          carePlans_DTReceivingUnits
          carePlans_axonReceivingHours
          SalesForceMappedTherapy {
            id
            subTypeTitle
            subTypesalesforceTherapyTitle
            parentTypeId
            parentTypeTitle
            parentTypeEventId
          }
        }
        client {
          firstName
          availabilityLastModificationDate
          lastName
          id
          clinic {
            name
            id
            abbreviation
          }
          profile {
            id
            dob
            personalEmail
            cellPhone
          }
          dayAvailabilities {
            id
            dayOfWeek
            endTime
            startTime
          }
          availabilityAudit {
            actionAt
            id
          }
        }
      }
      Count
      currentPageLength
    }
  }
`;

export const GET_WAITLIST_INITIAL_LOAD_DATA = gql`
  query {
    getWaitListIntialLoadData {
      waitListClientLocations {
        id
        title
        color
        borderColor
      }
      filterList {
        clinicOptionsList {
          abbreviation
          name
          id
        }
        waitListAvailabilityTime {
          title
        }
        waitListReasons {
          title
        }
        waitListStatus {
          title
        }
        waitListTheraphyTypies {
          title
        }
        waitListServices {
          title
        }
        smartSchedulingStatus {
          title
        }
      }
      filterSelections {
        id
        typeName
      }
    }
  }
`;

export const GET_CLIENT_CARE_PLAN = gql`
  query($clientID: ID!) {
    carePlan(clientID: $clientID) {
      recommendedEvaluations {
        ...RecommendedEvaluationData
      }
      recommendedTherapies {
        ...RecommendedTherapyData
      }
    }
    waitlistStatusOptions {
      id
      value
    }
    appointmentTypes {
      ...AppointmentTypeData
    }
    appointmentABATypes {
      ...AppointmentTypeData
    }
  }
  ${appointmentTypesFragments.appointmentTypesData}
  ${carePlanFragments.recommendedEvaluationData}
  ${carePlanFragments.recommendedTherapyData}
`;

export const GET_EDIT_CARE_PLAN_DATA = gql`
  query($clientID: ID!) {
    carePlan(clientID: $clientID) {
      recommendedEvaluations {
        ...RecommendedEvaluationData
      }
      recommendedTherapies {
        ...RecommendedTherapyData
      }
    }
    appointmentTypes {
      id
      title
      isClinical
      subTypes {
        id
        title
      }
    }
    appointmentABATypes {
      id
      title
      isClinical
      subTypes {
        id
        title
      }
    }
    clientEvaluationResponses {
      ACCEPT
      DECLINE
      __typename @skip(if: true)
    }
    quantityPatterns {
      VISITS
      HOURS
      __typename @skip(if: true)
    }
  }
  ${carePlanFragments.recommendedEvaluationData}
  ${carePlanFragments.recommendedTherapyData}
`;
