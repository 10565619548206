import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { IFullAppointmentType, IOption } from 'model/v2';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid } from 'semantic-ui-react';
import 'assets/sass/MyStyles.override.style.scss';
import { mapToValuedOption } from 'utils/mappers/form';
import { FormItem } from 'views/containers/form';

import { ABAWrapper } from '../style';
import FormItemNolabel from 'views/containers/form/formItem/formItemNolabel';
import { STYLE_CLASS } from 'utils/constants/appointmentsTypes';
import WeeklyHoursAutoComplete from './WeeklyHoursAutoComplete';
import { getSingleAppointmentTypeIds } from 'utils/mappers/smartSchedule';

interface Props {
  apptTypes: IFullAppointmentType[];
  smart: boolean;
  setIsSingleAppt: Dispatch<SetStateAction<boolean | undefined>>;
  setSelectedAppointment: Dispatch<SetStateAction<string>>;
}

const ABACarePlan: React.FC<Props> = ({
  apptTypes,
  smart,
  setIsSingleAppt,
  setSelectedAppointment
}) => {
  const methods = useFormContext();
  const [isSelected, setIsSelected] = useState(false);
  const [
    isSingleSessionDurationSelected,
    setIsSingleSessionDurationSelected
  ] = useState(false);

  const apptOptionsList: IOption[] = useMemo(
    () => mapToValuedOption(apptTypes),
    [apptTypes]
  );

  useEffect(() => {
    setIsSelected(false);
  }, [smart]);

  const errorCheckSingleSessionDuration = useMemo(() => {
    return methods.errors.aba?.singleSessionDuration !== undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.errors.aba?.singleSessionDuration]);

  const selectedAppType = methods.watch('appType');
  const isSingleAppt = getSingleAppointmentTypeIds(
    process.env.REACT_APP_STAGE
  ).includes(selectedAppType);
  useEffect(() => {
    setIsSingleAppt(isSingleAppt);
  }, [isSingleAppt, setIsSingleAppt]);
  const appointmentTypeSelect = useCallback(
    val => {
      const selectedApptTypes = apptTypes.find(apptType => {
        return apptType.id === val;
      });
      setIsSelected(true);
      methods.setValue('appType', val);
      methods.setValue(
        'appTypeLabel',
        `Appointment Type: ${selectedApptTypes?.title}`
      );
      if (selectedApptTypes?.title) {
        setSelectedAppointment(selectedApptTypes.title);
      }
    },
    [apptTypes, methods]
  );
  return (
    <ABAWrapper>
      <Grid.Row className="type-row">
        <Grid.Column>
          <FormItemNolabel optional={false}>
            <Controller
              name="appTypeLabel"
              control={methods.control}
              render={fieldProps => (
                <Select
                  className={
                    isSelected
                      ? STYLE_CLASS.SELECTED_STYLE
                      : STYLE_CLASS.UNSELECTED_STYLE
                  }
                  placeholder={'Select Appointment Type'}
                  options={apptOptionsList}
                  field={fieldProps}
                  onSelect={appointmentTypeSelect}
                  errors={methods.errors}
                />
              )}
            />
          </FormItemNolabel>
        </Grid.Column>
      </Grid.Row>
      <Grid>
        {isSingleAppt ? (
          <>
            <Grid.Row className="session" floated="left" width={60}>
              Session Information
            </Grid.Row>
            <Grid.Row floated="left" columns={2} className="lab">
              <Grid.Column width={7}>
                <FormItem optional={false} label="Session Duration">
                  <Controller
                    name="aba.singleSessionDuration"
                    control={methods.control}
                    render={fieldProps => (
                      <WeeklyHoursAutoComplete
                        isSelected={isSingleSessionDurationSelected}
                        value={fieldProps.value}
                        name="aba.singleSessionDuration"
                        errorCheck={errorCheckSingleSessionDuration}
                        setIsSelected={setIsSingleSessionDurationSelected}
                      />
                    )}
                  />
                </FormItem>
              </Grid.Column>
            </Grid.Row>
          </>
        ) : (
          <></>
        )}
      </Grid>
    </ABAWrapper>
  );
};
export default React.memo(ABACarePlan);
