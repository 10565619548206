import React, { Dispatch, useEffect, useState } from 'react';
import {
  NewAppointmentFormContainer,
  NewAppointmentFormWrapperV2
} from './style';
import { ICalendarResource } from 'model/calendar/filters';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import AdminAppointmentForm from './AdminAppointmentFormV2';
import ClientAppointmentForm from './ClientAppointmentFormV2';
import {
  IFormEvent,
  SidebarState,
  IClient,
  IFullAppointmentType
} from 'model/v2';
import FormHeader from './FormHeader';
import SmartScheduling from './SmartScheduling';
import { SIDEBAR_ACTIONS } from '../AppointmentSidebar';
import { useLocation, useHistory } from 'react-router';
import { useDeleteWhenCancelAppointmentsIds } from 'api/graphql/v2/hooks/recurringEvents';
import FindSubstitute from './FindSubstitute';
import ClientCallOut from './ClientCallOut';

interface Props {
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  changeFormHandler: (val: IFormEvent) => void; //TODO::check how to handle that in the new form
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  visible: boolean;
  apptTypes: IFullAppointmentType[];
  loadingApptTypes: boolean;
  adminTypes: IFullAppointmentType[];
  isDTAppt?: Boolean;
  parent_Type_ID?: number;
  subType_Id?: number;
}
const AppointmentForm: React.FC<Props> = ({
  setVisible,
  sidebarState,
  changeFormHandler,
  paginationArray,
  setPaginationArray,
  visible,
  apptTypes,
  loadingApptTypes,
  adminTypes,
  isDTAppt,
  parent_Type_ID,
  subType_Id
}) => {
  const [smart, setSmart] = useState(
    (sidebarState?.action !== SIDEBAR_ACTIONS.EDIT &&
      sidebarState?.action !== SIDEBAR_ACTIONS.SUBSTITUTE &&
      sidebarState?.action !== SIDEBAR_ACTIONS.FIND_MAKEUP &&
      sidebarState?.event?.client !== undefined) ||
      sidebarState?.event?.isDTAppt === true
  );

  //TODO: Remove this when smart schedule is working from planner URL
  const location = useLocation();
  const history = useHistory();
  const isSmartEnabledOnPlanner =
    sidebarState?.event?.isDTAppt || sidebarState?.event?.isABAAppt
      ? true
      : false;

  useEffect(() => {
    if (isSmartEnabledOnPlanner) {
      setSmart(
        sidebarState?.action !== SIDEBAR_ACTIONS.EDIT &&
          sidebarState?.event?.client !== undefined
      );
    } else {
      setSmart(false);
    }
  }, [isSmartEnabledOnPlanner, location, sidebarState, visible, history]);
  if (visible === false && sidebarState.action === 'Edit') {
    history.push({
      pathname: `/planner`
    });
  }
  const [appointmentCategory, setAppointmentCategory] = useState(() => {
    if (sidebarState.event?.appointmentType?.eventType?.name) {
      return sidebarState.event.appointmentType.eventType.name;
    }
    const provider = sidebarState.event?.provider;
    if (provider?.speciality?.department) {
      const department = provider.speciality?.department;
      // TODO:: Remove this after adding MED option
      return department === 'MED'
        ? APPOINTMENT_CATEGORY[4].value
        : department === APPOINTMENT_CATEGORY[1].value
        ? APPOINTMENT_CATEGORY[1].value
        : department === APPOINTMENT_CATEGORY[0].value
        ? APPOINTMENT_CATEGORY[0].value
        : APPOINTMENT_CATEGORY[2].value;
    }
    return isDTAppt === true
      ? APPOINTMENT_CATEGORY[1].value
      : APPOINTMENT_CATEGORY[0].value;
  });
  const [isCustom, setIsCustom] = useState(false);
  const [currClient, setCurrClient] = useState<IClient>();
  const [action, setAction] = useState<string>(sidebarState.action);

  const {
    deleteWhenCancelAppointmentsIds
  } = useDeleteWhenCancelAppointmentsIds(sidebarState.event?.id === undefined);

  //@todo check FWC
  return (
    <NewAppointmentFormContainer>
      <NewAppointmentFormWrapperV2>
        <FormHeader setVisible={setVisible} action={action} />
        {sidebarState?.action === SIDEBAR_ACTIONS.SUBSTITUTE ? (
          <>
            <FindSubstitute
              setVisible={setVisible}
              sidebarState={sidebarState}
              setPaginationArray={setPaginationArray}
              paginationArray={paginationArray}
              appointmentCategory={appointmentCategory}
              setSmart={setSmart}
              smart={smart}
              isCustom={isCustom}
              setAppointmentCategory={setAppointmentCategory}
              changeFormHandler={changeFormHandler}
              currClient={currClient}
              setCurrClient={setCurrClient}
            />
          </>
        ) : sidebarState?.action === SIDEBAR_ACTIONS.FIND_MAKEUP ? (
          <>
            <ClientCallOut
              setVisible={setVisible}
              sidebarState={sidebarState}
              setPaginationArray={setPaginationArray}
              paginationArray={paginationArray}
              appointmentCategory={appointmentCategory}
              setSmart={setSmart}
              smart={smart}
              isCustom={isCustom}
              setAppointmentCategory={setAppointmentCategory}
              changeFormHandler={changeFormHandler}
              currClient={currClient}
              setCurrClient={setCurrClient}
            />
          </>
        ) : (
          <>
            {smart && (
              <SmartScheduling
                setVisible={setVisible}
                sidebarState={sidebarState}
                setPaginationArray={setPaginationArray}
                paginationArray={paginationArray}
                appointmentCategory={appointmentCategory}
                setSmart={setSmart}
                smart={smart}
                isCustom={isCustom}
                setAppointmentCategory={setAppointmentCategory}
                changeFormHandler={changeFormHandler}
                currClient={currClient}
                setCurrClient={setCurrClient}
                apptTypes={apptTypes}
                loadingApptTypes={loadingApptTypes}
                adminTypes={adminTypes}
                setAction={setAction}
                parent_Type_ID={parent_Type_ID}
                subType_Id={subType_Id}
              />
            )}
            {!smart &&
              appointmentCategory === APPOINTMENT_CATEGORY[3].value && (
                <AdminAppointmentForm
                  setVisible={setVisible}
                  sidebarState={sidebarState}
                  setPaginationArray={setPaginationArray}
                  paginationArray={paginationArray}
                  appointmentCategory={appointmentCategory}
                  setSmart={setSmart}
                  setIsCustom={setIsCustom}
                  smart={smart}
                  isCustom={isCustom}
                  setAppointmentCategory={setAppointmentCategory}
                  changeFormHandler={changeFormHandler}
                  deleteWhenCancelAppointmentsIds={
                    deleteWhenCancelAppointmentsIds?.deleteWhenCancelAppointmentsIds
                  }
                  adminTypes={adminTypes}
                  loadingApptTypes={loadingApptTypes}
                />
              )}
            {!smart &&
              appointmentCategory !== APPOINTMENT_CATEGORY[3].value && (
                <ClientAppointmentForm
                  setIsCustom={setIsCustom}
                  setVisible={setVisible}
                  sidebarState={sidebarState}
                  setPaginationArray={setPaginationArray}
                  paginationArray={paginationArray}
                  appointmentCategory={appointmentCategory}
                  setSmart={setSmart}
                  smart={smart}
                  isCustom={isCustom}
                  setAppointmentCategory={setAppointmentCategory}
                  changeFormHandler={changeFormHandler}
                  currClient={currClient}
                  setCurrClient={setCurrClient}
                  deleteWhenCancelAppointmentsIds={
                    deleteWhenCancelAppointmentsIds?.deleteWhenCancelAppointmentsIds
                  }
                  apptTypes={apptTypes}
                  loadingApptTypes={loadingApptTypes}
                />
              )}
          </>
        )}
      </NewAppointmentFormWrapperV2>
    </NewAppointmentFormContainer>
  );
};
export default React.memo(AppointmentForm);
