import React from 'react';
import { AppointmentFormHead } from './style';
import { CloseOutlined } from '@ant-design/icons';
import { SIDEBAR_ACTIONS } from '../AppointmentSidebar';
import { selectedItemToSubstituteVar } from 'utils/cache/calendar';

interface Props {
  setVisible: (val: boolean) => void;
  action: string;
}

const FormHeader: React.FC<Props> = ({ setVisible, action }: Props) => {
  const handleclose = async () => {
    await setVisible(false);
    selectedItemToSubstituteVar(0);
  };
  if (
    action !== SIDEBAR_ACTIONS.SUBSTITUTE &&
    action !== SIDEBAR_ACTIONS.FIND_MAKEUP
  )
    selectedItemToSubstituteVar(0);
  return (
    <AppointmentFormHead>
      {action === SIDEBAR_ACTIONS.SUBSTITUTE ? (
        <>
          <label>Find Substitute</label>
        </>
      ) : action === SIDEBAR_ACTIONS.FIND_MAKEUP ? (
        <>
          <label>Find Makeup</label>
        </>
      ) : action === SIDEBAR_ACTIONS.POTENIAL_RESULTS ? (
        <>
          <label>{SIDEBAR_ACTIONS.POTENIAL_RESULTS}</label>
        </>
      ) : (
        <>
          <label>{action} Appointment</label>
        </>
      )}
      <CloseOutlined onClick={handleclose} />
    </AppointmentFormHead>
  );
};
export default React.memo(FormHeader);
