import moment from 'moment';
import {
  IFullAppointmentType,
  IProvider,
  IConflict,
  IConflictMapped,
  IFormEvent
} from 'model/v2';
import { IOption } from 'model';

export const STEPS = {
  VALIDATE_FORM: 1,
  WARNINGS: 2,
  CONFLICTS: 3,
  SUBMIT: 4,
  OBSERVER_WARNING: 5,
  MILEAGE_WARNING: 6
};

export const mapToConflict = (
  formData: IFormEvent | null,
  conflicts: IConflict[],
  providersMap: Map<any, IProvider>,
  apptTypes: IFullAppointmentType[]
): IConflictMapped[] => {
  const providerId = formData?.selectedOpening
    ? formData.selectedOpening.provider?.id
    : formData?.provider?.id;
  const provider = providersMap.get(providerId);
  const appType = apptTypes?.find(x => x.id === formData?.appType);
  const mappedConflicts = (conflicts || [])
    .map(
      ({
        startDate,
        endDate,
        conflictAppointmentTitle,
        locationType,
        locationCategory
      }) => ({
        title: provider?.name,
        startDate,
        endDate,
        locationType: locationType || '',
        appType: conflictAppointmentTitle //Addning the conflict title to the frontend
          ? conflictAppointmentTitle
          : appType?.title,
        category: locationCategory || ''
      })
    )
    .sort((c1, c2) => moment(c2.startDate).diff(c1.endDate));
  return mappedConflicts;
};

export const getConflictsVariables = (
  formEvent: IFormEvent | null,
  mappedEvents: any[],
  recurringOptions?: IOption[]
) => {
  return mappedEvents.map(mapped => ({
    reschedule: !!formEvent?.id,
    masterId: formEvent?.master?.id,
    recurring: formEvent?.id
      ? formEvent?.recurrent === recurringOptions![1].value
      : parseInt(mapped?.recurrencePattern?.recurringEvery || '0') > 0,
    providerId: mapped.provider?.id,
    startDate: mapped.startDate,
    endDate: mapped.endDate,
    recurringUntil: mapped.recurrencePattern?.recurringUntil,
    recurringEvery: mapped.recurrencePattern?.recurringEvery
  }));
};
