import { IClinic } from 'model/v2';
import { useState } from 'react';
import { useQuery } from 'react-apollo';
import { GET_CLINICS } from '../queries/Clinics';

export const useClinicDataMap = (status: String) => {
  const [providersMap, setProvidersMap] = useState(new Map());
  const [clinicsMap, setClinicsMap] = useState(new Map());
  const { loading: loadingClinics, error: errorClinics } = useQuery(
    GET_CLINICS,
    {
      variables: { status: status },
      onCompleted: data => {
        const newClinicsMap = new Map();
        const newProvidersMap = new Map();
        data?.clinicsWithProvidersWithRooms?.forEach((clinic: IClinic) => {
          newClinicsMap.set(clinic.id, {
            id: clinic.id,
            name: clinic.name,
            abbreviation: clinic.abbreviation,
            clinicHub: clinic.clinicHub,
            clinicType: clinic.clinicType,
            clinicState: clinic.address?.state,
            providers: clinic.providers?.map(provider => {
              const mapped = {
                id: provider.id,
                name: provider.name,
                telehealth: provider.telehealth,
                firstName: provider.firstName,
                lastName: provider.lastName,
                displayName: provider.displayName,
                appointmentTypes: provider.appointmentTypes,
                clinic: {
                  id: clinic.id,
                  name: clinic.name,
                  abbreviation: clinic.abbreviation
                },
                speciality: provider.speciality,
                status: provider.status,
                telehealthLinks: provider.telehealthLinks,
                corticaPrograms: provider.corticaPrograms
              };
              newProvidersMap.set(provider.id, mapped);
              return mapped;
            }),
            rooms: clinic.rooms?.map(x => {
              return {
                ...x,
                clinic: {
                  id: clinic.id
                }
              };
            })
          });
        });
        setProvidersMap(newProvidersMap);
        setClinicsMap(newClinicsMap);
      },
      fetchPolicy: 'no-cache'
    }
  );

  return { providersMap, clinicsMap, loadingClinics, errorClinics };
};
