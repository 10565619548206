import gql from 'graphql-tag';
import { smartResultCard } from '../fragments/SmartSchedule';
import { TIMEZONE } from 'utils/constants/default';
import { providerFragments } from '../fragments/Providers';

export const GET_DT_SMART_RESULT = gql`
  query(
    $appointmentTypeId: Int!
    $clientId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $sessionDuration: Int!
    $timezone: String
    $isIgnoreClientProfileAvailability: Boolean
    $clientAvailabilityFilter: ClientAvailabilityFilter
    $providerAvailabilityFilter: ProviderAvailabilityFilter
  ) {
    DTSmartScheduleOpenings(
      appointmentTypeId: $appointmentTypeId
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
      sessionDuration: $sessionDuration
      timezone: $timezone
      isIgnoreClientProfileAvailability: $isIgnoreClientProfileAvailability
      clientAvailabilityFilter: $clientAvailabilityFilter
      providerAvailabilityFilter: $providerAvailabilityFilter
    ) {
      ...SmartResultCard
    }
  }
  ${smartResultCard}
`;

export const GET_ABA_SMART_RESULT = gql`
  query(
    $appointmentTypeId: Int!
    $clientId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $sessionDuration: SessionDurationArgs!
    $timezone: String
    $isIgnoreClientProfileAvailability: Boolean
    $clientAvailabilityFilter: ClientAvailabilityFilter
    $providerAvailabilityFilter: ProviderAvailabilityFilter
  ) {
    ABASmartScheduleOpenings(
      appointmentTypeId: $appointmentTypeId
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
      sessionDuration: $sessionDuration
      timezone: $timezone
      isIgnoreClientProfileAvailability: $isIgnoreClientProfileAvailability
      clientAvailabilityFilter: $clientAvailabilityFilter
      providerAvailabilityFilter: $providerAvailabilityFilter
    ) {
      ...SmartResultCard
    }
  }
  ${smartResultCard}
`;

export const GET_ABA_SMART_RESULT_WITH_RANGES = gql`
  query(
    $appointmentTypeId: Int!
    $clientId: Int!
    $startDate: DateTime
    $endDate: DateTime
    $sessionDuration: SessionDurationArgs!
    $timezone: String
    $isIgnoreClientProfileAvailability: Boolean
    $clientAvailabilityFilter: ClientAvailabilityFilter
    $providerAvailabilityFilter: ProviderAvailabilityFilter
  ) {
    ABASmartScheduleOpeningsWithRanges(
      appointmentTypeId: $appointmentTypeId
      clientId: $clientId
      startDate: $startDate
      endDate: $endDate
      sessionDuration: $sessionDuration
      timezone: $timezone
      isIgnoreClientProfileAvailability: $isIgnoreClientProfileAvailability
      clientAvailabilityFilter: $clientAvailabilityFilter
      providerAvailabilityFilter: $providerAvailabilityFilter
    ) {
      clientTimezone
      openingCards {
        timeRanges {
          effectiveStartDate
          fullProviderRanges {
            startTime
            endTime
          }
          clientAvailableRanges {
            startTime
            endTime
          }
        }
        provider {
          id
          name
          telehealthLinks
          clinic {
            id
            timezone
            abbreviation
          }
          speciality {
            id
            abbreviation
          }
        }
        appointmentType {
          id
          title
        }
        clinic {
          id
          name
          abbreviation
        }
        endDate
        dayOfWeek
        isTelehealth
        isInClinic
        isOffsite
        allowedLocations
      }
    }
  }
`;

export const GET_SINGLE_APPT_SMART_RESULT = gql`
  query(
    $clientId: Int!
    $appointmentTypeId: Int!
    $apptSubTypeId: Int
    $startTime: DateTime
    $endTime: DateTime
    $providerAvailabilityFilter: ProviderAvailabilityFilter
    $isIgnoreClientProfileAvailability: Boolean
    $clientAvailabilityFilter: ClientAvailabilityFilter
    $duration: Int
    $clinicFilter: ClinicFilter!
    $locationFilter: LocationFilter!
  ) {
    SingleEventSmartScheduleOpenings(
      clientId: $clientId
      appointmentTypeId: $appointmentTypeId
      apptSubTypeId: $apptSubTypeId
      duration: $duration
      startTime: $startTime
      endTime: $endTime
      providerAvailabilityFilter: $providerAvailabilityFilter
      isIgnoreClientProfileAvailability: $isIgnoreClientProfileAvailability
      clientAvailabilityFilter: $clientAvailabilityFilter
      clinicFilter: $clinicFilter
      locationFilter: $locationFilter
    ) {
      date
      times {
        startTime
        endTime
      }
      provider {
        id
        name
        speciality {
          abbreviation
        }
        clinic {
          abbreviation
          timezone
        }
        telehealthLinks
      }
      clientTimezone
      apptTypeId
      inPersonClinics {
        id
        name
        abbreviation
      }
      locations {
        isTelehealth
        isInClinic
        isOffsite
      }
      dayOfWeek
      telehealthClinic {
        id
        name
        abbreviation
      }
    }
  }
`;

export const GET_SMART_CONFLICTS = gql`
  query($conflictInputs: [IConflictInput!]!) {
    getSmartEventConflicts(conflictInputs: $conflictInputs , timezone: "${TIMEZONE}") {
      eventsCount
      conflicts {
        startDate
        endDate
      }
    }
  }
`;

export const GET_ABA_PRECALCULATION_PROVIDERS = gql`
  query(
    $clientId: Int!
    $clinicId: Int!
    $appointmentTypeId: Int!
    $isClientNetwork: Boolean!
    $isClientHomeNetwork: Boolean
    $isAllClinics: Boolean
  ) {
    getSmartABAProviders(
      clientId: $clientId
      clinicId: $clinicId
      appointmentTypeId: $appointmentTypeId
      isClientNetwork: $isClientNetwork
      isClientHomeNetwork: $isClientHomeNetwork
      isAllClinics: $isAllClinics
    ) {
      ...Provider
    }
  }
  ${providerFragments.provider}
`;

export const GET_DT_PRECALCULATION_PROVIDERS = gql`
  query(
    $clientId: Int!
    $clinicId: Int!
    $appointmentTypeId: Int!
    $isClientNetwork: Boolean!
    $isClientHomeNetwork: Boolean
    $isAllClinics: Boolean
  ) {
    getSmartDTProviders(
      clientId: $clientId
      clinicId: $clinicId
      appointmentTypeId: $appointmentTypeId
      isClientNetwork: $isClientNetwork
      isClientHomeNetwork: $isClientHomeNetwork
      isAllClinics: $isAllClinics
    ) {
      ...Provider
    }
  }
  ${providerFragments.provider}
`;

export const GET_DT_PROVIDERS_BY_APPT_AND_CLINIC = gql`
  query(
    $clientId: Int!
    $clinicId: Int!
    $appointmentTypeId: Int!
    $isClientNetwork: Boolean!
    $isClientHomeNetwork: Boolean
    $isAllClinics: Boolean
  ) {
    getSmartDTProviders(
      clientId: $clientId
      clinicId: $clinicId
      appointmentTypeId: $appointmentTypeId
      isClientNetwork: $isClientNetwork
      isClientHomeNetwork: $isClientHomeNetwork
      isAllClinics: $isAllClinics
    ) {
      ...Provider
    }
  }
  ${providerFragments.provider}
`;

export const GET_MED_PRECALCULATION_PROVIDERS = gql`
  query(
    $clientId: Int!
    $clinicId: Int!
    $appointmentTypeId: Int!
    $isClientNetwork: Boolean!
    $isClientHomeNetwork: Boolean
    $isAllClinics: Boolean
  ) {
    getSmartMEDProviders(
      clientId: $clientId
      clinicId: $clinicId
      appointmentTypeId: $appointmentTypeId
      isClientNetwork: $isClientNetwork
      isClientHomeNetwork: $isClientHomeNetwork
      isAllClinics: $isAllClinics
    ) {
      ...Provider
    }
  }
  ${providerFragments.provider}
`;
