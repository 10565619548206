import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';

export const YES_NO_LIST = [
  { id: 'Yes', value: 'Yes' },
  { id: 'No', value: 'No' }
];

export const GENDER_LIST = [
  { id: 'Male', value: 'Male' },
  { id: 'Female', value: 'Female' },
  { id: 'Other', value: 'Other' }
];
export const ClientactiveStatus = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' }
];

export const PROVIDER_GENDER_LIST = [
  { id: 'Male', value: 'Male' },
  { id: 'Female', value: 'Female' },
  { id: 'Non-Binary Person', value: 'Non-Binary Person' },
  { id: 'Prefer Not to Say', value: ' Prefer Not to Say' }
];

export const PROVIDER_ALLERGIC_TYPE = [
  { id: 'Dogs', value: 'Dogs' },
  { id: 'Cats', value: 'Cats' },
  { id: 'Other Animals', value: 'Other Animals' },
  { id: 'Multiple/All', value: 'Multiple/All' }
];
export const TRUE_FALSE_LIST = [
  { id: 'Yes', value: true },
  { id: 'No', value: false }
];

export type providerStatusListType = {
  ACTIVE: 'Active';
  INACTIVE: 'Inactive';
  ACCOMMODATION: 'Accommodation';
};

export const SEX_LIST = [
  { id: 'Male', value: 'Male' },
  { id: 'Female', value: 'Female' }
];

export const PHONE_PREFERENCE_LIST = [
  { id: 'Mobile', value: 'Mobile' },
  { id: 'Home', value: 'Home' }
];

export const COMM_LIST = [
  { id: 'SMS', value: 'SMS' },
  { id: 'Phone Call', value: 'Phone Call' },
  { id: 'Email', value: 'Email' }
];

export const MEDICAL_STAGE_LIST = [
  { id: 'A1', value: 'A1' },
  { id: 'A2', value: 'A2' },
  { id: 'A3', value: 'A3' },
  { id: 'B1', value: 'B1' },
  { id: 'B2', value: 'B2' },
  { id: 'B3', value: 'B3' },
  { id: 'C1', value: 'C1' },
  { id: 'C2', value: 'C2' },
  { id: 'C3', value: 'C3' },
  { id: 'D1', value: 'D1' },
  { id: 'D2', value: 'D2' },
  { id: 'D3', value: 'D3' }
];

export const GENERIC_LOCATION_TYPES = ['in-clinic', 'off-site', 'telehealth'];

export const LOCATION_TYPE_LIST: Record<
  string,
  string
>[] = getFeatureAvailability(FEATURES.TELEHEALTH)
  ? [
      { id: 'In Clinic', value: 'In Clinic' },
      { id: 'Off-Site', value: 'Off-Site' },
      { id: 'Telehealth', value: 'Telehealth' }
    ]
  : [
      { id: 'In Clinic', value: 'In Clinic' },
      { id: 'Off-Site', value: 'Off-Site' }
    ];

export const SCHEDULE_TYPE_LIST = [
  { id: 'One-Time', value: 'One-Time' },
  { id: 'Repeats every', value: 'Repeats every' }
];

export const CLINIC_PROVIDERS = [
  { id: 'homeClinic', value: 'Home Clinic' },
  { id: 'homeClinicNetwork', value: 'Home Clinic Network' },
  { id: 'allClinics', value: 'All Clinics' },
  { id: 'LicensedClinicians', value: 'Licensed Clinicians' }
];

export const PAYMENT_METHODS = [
  { id: 'insurance', value: 'Insurance' },
  { id: 'selfpay', value: 'Self Pay' }
];

export const CLIENT_FREQUENCY_PREFERENCE = [
  { id: 'most_days', value: 'Most Days' },
  { id: 'fewest_days', value: 'Fewest Days' }
];

export const SCHEDULE_REPEAT_LIST = [
  { id: 1, value: '1' },
  { id: 2, value: '2' },
  { id: 3, value: '3' },
  { id: 4, value: '4' }
];

export const LOCATION_CATEGORY_LIST = [
  { id: 'Home', value: 'Home' },
  { id: 'School', value: 'School' },
  { id: 'Daycare', value: 'Daycare' },
  { id: 'Community', value: 'Community' }
];

export const CLIENT_PROFILE_TABS = () => {
  return getFeatureAvailability(FEATURES.NOTES)
    ? {
        SCHEDULE: '0',
        AUTHORIZATION: '1',
        CARE_PLAN: '2',
        NOTE: '3'
      }
    : {
        SCHEDULE: '0',
        AUTHORIZATION: '1',
        CARE_PLAN: '2'
      };
};

export const ADMIN_DEFAULT_CANCEL_REASON = 'canr08';

export const ATHENA_DEFAULT_DELETE_REASON = 'canr10';

export const EVENT_RESCHEDULE_OPTIONS = (
  recurrent: boolean,
  recurringEventsCount?: number
) => {
  return process.env.REACT_APP_RECURRING_ACTIONS === 'off' || !recurrent
    ? []
    : [
        {
          label: getFeatureAvailability(FEATURES.RESCHEDULE_MODAL_UPDATES)
            ? 'Only this event'
            : 'This event',
          value: 'single'
        },
        {
          label: getFeatureAvailability(FEATURES.RESCHEDULE_MODAL_UPDATES)
            ? `Update the selected series (${recurringEventsCount}) events`
            : 'This and Following Events',
          value: 'all'
        }
      ];
};

export const EVENT_CANCEL_OPTIONS = (
  recurrent: boolean,
  isABA?: boolean,
  isAdmin?: boolean,
  recurringEventsCount?: number
) => {
  let eventActionString = isAdmin ? 'Delete' : 'Cancel';
  return process.env.REACT_APP_RECURRING_ACTIONS === 'off' ||
    (!recurrent && !isABA)
    ? []
    : isABA && !recurrent
    ? [
        {
          label: getFeatureAvailability(FEATURES.CANCEL_MODAL_UPDATES)
            ? 'Cancel this event'
            : 'Cancel this event',
          value: 'single'
        },
        {
          label: 'Delete this event',
          value: 'deleteSingle'
        }
      ]
    : isABA && recurrent
    ? [
        {
          label: getFeatureAvailability(FEATURES.CANCEL_MODAL_UPDATES)
            ? 'Cancel Only the selected (1) events'
            : 'Cancel Only the selected (1) events',
          value: 'single'
        },
        {
          label: 'Delete Only the selected (1) events',
          value: 'deleteSingle'
        },
        {
          label: getFeatureAvailability(FEATURES.CANCEL_MODAL_UPDATES)
            ? `Delete the entire (1) selected series; this will remove (${recurringEventsCount}) events`
            : 'This and Following Events',
          value: 'all'
        }
      ]
    : [
        {
          label: getFeatureAvailability(FEATURES.CANCEL_MODAL_UPDATES)
            ? `${eventActionString} Only the selected (1) events`
            : 'This event',
          value: 'single'
        },
        {
          label: getFeatureAvailability(FEATURES.CANCEL_MODAL_UPDATES)
            ? `Delete the entire (1) selected series; this will remove (${recurringEventsCount}) events`
            : 'This and Following Events',
          value: 'all'
        }
      ];
};

export const EVENT_BULK_CANCEL_OPTIONS = (
  recurrent: boolean,
  selectedEventsCount?: number,
  seriesEventsCount?: number,
  totalDeletedEvents?: number,
  singleEventsCounts?: number,
  appointmentTypesList?: any
) => {
  let pureABA = true;
  let isAdmin = false;
  if (appointmentTypesList?.length) {
    for (var singleSelected of appointmentTypesList.values()) {
      if (
        singleSelected.appointmentType?.eventType?.name !==
        APPOINTMENT_CATEGORY[0].value
      ) {
        pureABA = false;
      }
      if (
        singleSelected.appointmentType?.eventType?.name ===
        APPOINTMENT_CATEGORY[3].value
      ) {
        isAdmin = true;
      }
    }
  }
  let eventActionString = isAdmin ? 'Delete' : 'Cancel';
  return process.env.REACT_APP_RECURRING_ACTIONS === 'off' &&
    !recurrent &&
    !pureABA
    ? []
    : !recurrent && pureABA
    ? [
        {
          label: `Cancel the selected (${selectedEventsCount}) event`,
          value: 'single'
        },
        {
          label: `Delete the selected (${selectedEventsCount}) event`,
          value: 'deleteSingle'
        }
      ]
    : recurrent && pureABA
    ? [
        {
          label: `Cancel the selected (${selectedEventsCount}) event`,
          value: 'single'
        },
        {
          label: `Delete the selected (${selectedEventsCount}) event`,
          value: 'deleteSingle'
        },
        {
          label: `Delete the entire (${seriesEventsCount}) selected series ${
            singleEventsCounts
              ? `and cancel (${singleEventsCounts}) events`
              : ''
          }; this will remove (${totalDeletedEvents}) events`,
          value: 'all'
        }
      ]
    : recurrent && !pureABA
    ? [
        {
          label: `${eventActionString} the selected (${selectedEventsCount}) event`,
          value: 'single'
        },
        {
          label: `Delete the entire (${seriesEventsCount}) selected series ${
            singleEventsCounts
              ? `and cancel (${singleEventsCounts}) events`
              : ''
          }; this will remove (${totalDeletedEvents}) events`,
          value: 'all'
        }
      ]
    : [];
};

export const GROUPED_CANCEL_OPTIONS = [
  {
    label: 'single event cancel',
    value: 'single'
  },
  {
    label: 'single event delete',
    value: 'deleteSingle'
  },
  {
    label: 'delete entire series',
    value: 'all'
  }
];

export const CALENDAR_VIEWS = ['day', 'week'];
export const PLANNER_VIEWS = ['planner day', 'planner week'];
export const APPOINTMENT_CATEGORY_SMART = {
  ABA: 'ABA',
  DT: 'DT',
  FWC: 'FWC',
  Admin: 'Admin',
  MED: 'MED'
};

export const PROVIDER_FILTER_CLINICS_TYPE_VALUES = {
  clientNetwork: 1,
  clientHome: 2,
  allClinics: 3
};
export const MAIN_CALENDAR_VIEWS_OPTIONS = getFeatureAvailability(
  FEATURES.CALENDAR_PREVIEW_DAY_WEEK
)
  ? [
      { id: PLANNER_VIEWS[0], value: 'Planner Day' },
      { id: PLANNER_VIEWS[1], value: 'Planner Week' },
      { id: CALENDAR_VIEWS[0], value: 'Day' },
      { id: CALENDAR_VIEWS[1], value: 'Week' }
    ]
  : [
      { id: PLANNER_VIEWS[0], value: 'Planner Day' },
      { id: PLANNER_VIEWS[1], value: 'Planner Week' }
    ];

export const EVENT_CATEGORY: Record<string, string>[] = [
  { value: 'clientEvent', label: 'Client' },
  { value: 'adminEvent', label: 'Admin' },
  { value: 'abaEvent', label: 'ABA' }
];

export const APPOINTMENT_CATEGORY: Record<string, string>[] = [
  { value: 'ABA', label: APPOINTMENT_CATEGORY_SMART.ABA },
  { value: 'DT', label: APPOINTMENT_CATEGORY_SMART.DT },
  { value: 'FWC', label: APPOINTMENT_CATEGORY_SMART.FWC },
  { value: 'Admin', label: APPOINTMENT_CATEGORY_SMART.Admin },
  { value: 'MED', label: APPOINTMENT_CATEGORY_SMART.MED }
];

export const EVENT_OCCURENCE = [
  { value: 1, label: 'Weekly' },
  { value: 2, label: 'Every 2 weeks' },
  { value: 3, label: 'Every 3 weeks' },
  { value: 4, label: 'Every 4 weeks' },
  { value: 'custom', label: 'Custom', disabled: true }
];

export const CLINIC_TYPES = [
  { id: 'hub', label: 'Hub' },
  { id: 'spoke', label: 'Spoke' },
  { id: '', label: '' }
];
export const WEEK_DAYS = [
  { value: 0, label: 'S' },
  { value: 1, label: 'M' },
  { value: 2, label: 'T' },
  { value: 3, label: 'W' },
  { value: 4, label: 'T' },
  { value: 5, label: 'F' },
  { value: 6, label: 'S' }
];

export const LOCATION_LIST = {
  inClinic: { text: 'In Clinic', value: 1 },
  teleHealth: { text: 'Telehealth', value: 2 },
  offSite: { text: 'Offsite', value: 3 }
};

export const CLIENT_FILTER_TIME = [
  {
    text: 'Morning - before 12 PM',
    value: 1,
    range: { from: '00:00', to: '12:00' }
  },
  {
    text: 'Mid Day - 12 PM - 3 PM',
    value: 2,
    range: { from: '12:00', to: '15:00' }
  },
  {
    text: 'Afternoon - 3 PM - 5 PM',
    value: 3,
    range: { from: '15:00', to: '17:00' }
  },
  {
    text: 'Evening - after 5 PM',
    value: 4,
    range: { from: '17:00', to: '24:00' }
  },
  { text: 'Custom Range', value: 5 }
];

export const CLIENT_FILTER_DAYS = [
  { text: 'Monday', value: 1 },
  { text: 'Tuesday', value: 2 },
  { text: 'Wednesday', value: 3 },
  { text: 'Thursday', value: 4 },
  { text: 'Friday', value: 5 }
];

export const DAYS_FILTER = [
  { text: 'Sunday', value: 0 },
  { text: 'Monday', value: 1 },
  { text: 'Tuesday', value: 2 },
  { text: 'Wednesday', value: 3 },
  { text: 'Thursday', value: 4 },
  { text: 'Friday', value: 5 },
  { text: 'Saturday', value: 6 }
];

export const CLIENT_AVAILABLE_DAYS: { [key: string]: string } = {
  '0': 'Sunday',
  '1': 'Monday',
  '2': 'Tuesday',
  '3': 'Wednesday',
  '4': 'Thursday',
  '5': 'Friday',
  '6': 'Saturday'
};

export const TimeSlots = [
  {
    id: 1,
    fromLabel: '7 AM',
    from: '7:00',
    toLabel: '12 PM',
    to: '12:00'
  },
  {
    id: 2,
    fromLabel: '12 PM',
    from: '12:00',
    toLabel: '3 PM',
    to: '15:00'
  },
  {
    id: 3,
    fromLabel: '3 PM',
    from: '15:00',
    toLabel: '7 PM',
    to: '19:00'
  }
];

export const PROVIDER_FILTER_CLINICS_TYPE = [
  {
    value: PROVIDER_FILTER_CLINICS_TYPE_VALUES.clientNetwork,
    label: "Client's Clinic Network"
  },
  {
    value: PROVIDER_FILTER_CLINICS_TYPE_VALUES.clientHome,
    label: "Client's Home Network"
  },
  {
    value: PROVIDER_FILTER_CLINICS_TYPE_VALUES.allClinics,
    label: 'All Clinics'
  }
];

export const CLIENT_LOCATIONS_DATA = [
  {
    id: 6,
    order: 6,
    title: 'Offsite and In Clinic',
    color: 'rgba(98, 141, 214, 0.2)',
    borderColor: '#628DD6'
  },
  {
    id: 3,
    order: 1,
    title: 'In Clinic and Telehealth',
    color: 'rgba(147, 236, 226, 0.2)',
    borderColor: '#93ece2'
  },
  {
    id: 5,
    order: 5,
    title: 'Offsite and Telehealth',
    color: 'rgba(243, 197, 222, 0.2)',
    borderColor: '#f3c5de'
  },
  {
    id: 7,
    order: 7,
    title: 'Any Availability',
    color: 'rgba(165, 138, 246, 0.2)',
    borderColor: '#a58af6'
  }
];
