import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { WebSocketLink } from 'apollo-link-ws';
import { onError } from 'apollo-link-error';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloLink } from 'apollo-link';
import { parse, stringify } from 'flatted';
import wsClient from './webSocketClient';
import UserCredentials from './types/userCredentials';

export default function getApolloLink(
  loadUserCredentials: () => Promise<UserCredentials>
) {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });

  const cleanTypename = new ApolloLink((operation, forward) => {
    const omitTypename = (key: string, value: any) =>
      key === '__typename' ? undefined : value;

    if (operation.variables && !operation.getContext().hasUpload) {
      operation.variables = parse(stringify(operation.variables), omitTypename);
    }

    return forward(operation);
  });

  const enabledApolloNetworkStatus = setContext((_, prevContext) => {
    return { ...prevContext, useApolloNetworkStatus: true };
  });

  const graphqlLink = createHttpLink({
    uri: process.env.REACT_APP_AWS_GRAPHQL_URL
  });

  const webSocket = new WebSocketLink(wsClient);

  const operationDirectionalLink = ApolloLink.split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    webSocket,
    graphqlLink
  );

  const loadLoggedInUser = setContext(async (_, prevContext) => {
    const loggedInUser = await loadUserCredentials();

    return { ...prevContext, loggedInUser };
  });

  const setAccessToken = new ApolloLink((operation, forward) => {
    const context = operation.getContext();
    const loggedInUser = context.loggedInUser;
    const loggedInUserName = loggedInUser.name || loggedInUser.username;

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        Authorization: 'Bearer ' + loggedInUser.accessToken,
        user: encodeURIComponent(loggedInUserName)
      }
    }));
    return forward(operation);
  });

  const terminatingLink = ApolloLink.empty();

  const terminateIfNoAccessToken = ApolloLink.split(
    operation => Boolean(operation.getContext().loggedInUser?.accessToken),
    ApolloLink.from([setAccessToken, operationDirectionalLink]),
    terminatingLink
  );

  return ApolloLink.from([
    errorLink,
    cleanTypename,
    enabledApolloNetworkStatus,
    loadLoggedInUser,
    terminateIfNoAccessToken
  ]);
}
