/* eslint-disable no-constant-condition */
import React from 'react';
import { HeaderWrapper } from './Style';
import CheckCircle from 'assets/img/CheckCircle.png';
import UserIcon from 'assets/img/User.png';
import { isCheckedIndex } from 'utils/validators/smartSchedule';
import { useFormContext } from 'react-hook-form';

interface IProps {
  appointmentTitle: string;
  result: any;
  index: number;
  isCheckedCard: boolean;
  updateSelectedCards: (idx: number, isChecked: boolean) => void;
}
const RecurringResultHeader: React.FC<IProps> = ({
  result,
  index,
  isCheckedCard,
  updateSelectedCards
}: IProps) => {
  const { setValue } = useFormContext();

  return (
    <HeaderWrapper>
      <header className="parent">
        <nav
          style={{ cursor: 'pointer' }}
          onClick={() => {
            setValue(isCheckedIndex(index), !isCheckedCard);
            updateSelectedCards(index, !isCheckedCard);
          }}
        >
          <ul>
            <li>
              <img
                alt="user"
                src={UserIcon}
                width={17}
                style={{ padding: '2px', marginBottom: '5px' }}
              />
            </li>
            <li style={{ width: '58%' }}>
              <label className="provider provider-cls">
                {result.provider.name || ''},{' '}
                {result.provider.speciality.abbreviation} (
                {result.provider.clinic.abbreviation})
              </label>
            </li>
            <li className="rectangle adjacent-cls">
              {result.timeSlots[0]?.clientFullyAvailable! && (
                <p>
                  Client Availability{' '}
                  <img src={CheckCircle} alt="checked circle" />
                </p>
              )}
              {result.timeSlots[0]?.timeSlot?.adjacentType! > 0 && (
                <p className="adjacent-p">
                  Adjacent <img src={CheckCircle} alt="checked circle" />
                </p>
              )}
            </li>
          </ul>
        </nav>
      </header>
    </HeaderWrapper>
  );
};
export default React.memo(RecurringResultHeader);
