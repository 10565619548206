import { GET_SMART_CONFLICTS } from 'api/graphql/v2/queries/SmartSchedule';
import {
  IConflictMapped,
  IEventConflicts,
  IFullAppointmentType,
  IMappedEvent,
  IProvider
} from 'model/v2';
import React, { useCallback, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { mapToConflict, STEPS } from '../utils';
import SmartConflictsFooter from './SmartConflictsFooter';
import SmartSubmitFooter from './SmartSubmitFooter';
import SmartValidationFooter from './SmartValidationFooter';

interface Props {
  setVisible: (val: boolean) => void;
  action: string;
  providersMap: Map<any, IProvider>;
  apptTypes: IFullAppointmentType[];
  noSelectedSlots: boolean;
  appointmentSubTypeId?: number;
  hasSingleDT: any;
}

const SmartFormFooter: React.FC<Props> = ({
  setVisible,
  providersMap,
  apptTypes,
  noSelectedSlots,
  appointmentSubTypeId,
  hasSingleDT
}: Props) => {
  const [saving, setSaving] = useState(false);
  const [step, setStep] = useState(STEPS.VALIDATE_FORM);
  const [mappedSmartEvents, setMappedSmartEvent] = useState<IMappedEvent[]>([]);
  const [smartEventConflicts, setSmartEventConflicts] = useState<
    IConflictMapped[][]
  >([]);
  const [doSmartConflictRequest] = useLazyQuery(GET_SMART_CONFLICTS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      const eventConflicts: IEventConflicts[] = data.getSmartEventConflicts;
      let isConflictsExist = false;
      const conflicts: IConflictMapped[][] = [];
      eventConflicts.forEach(eventConflict => {
        if (eventConflict.conflicts && eventConflict.conflicts.length > 0) {
          isConflictsExist = true;
          conflicts.push(
            mapToConflict(
              null,
              eventConflict.conflicts,
              providersMap,
              apptTypes
            )
          );
        }
      });
      setSmartEventConflicts(conflicts);
      if (isConflictsExist) {
        setStep(STEPS.CONFLICTS);
      } else {
        setStep(STEPS.SUBMIT);
      }
    }
  });
  const backToForm = useCallback(() => {
    setStep(STEPS.VALIDATE_FORM);
    setSaving(false);
  }, [setStep]);
  return (
    <>
      {step !== STEPS.CONFLICTS && (
        <SmartValidationFooter
          setMappedSmartEvent={setMappedSmartEvent}
          doSmartConflictRequest={doSmartConflictRequest}
          saving={saving}
          setStep={setStep}
          setSaving={setSaving}
          noSelectedSlots={noSelectedSlots}
          appointmentSubTypeId={appointmentSubTypeId}
          hasSingleDT={hasSingleDT}
        />
      )}
      {step === STEPS.CONFLICTS ? (
        <SmartConflictsFooter
          eventsConflicts={smartEventConflicts}
          setStep={setStep}
          backToForm={backToForm}
        />
      ) : (
        step === STEPS.SUBMIT && (
          <SmartSubmitFooter
            setSaving={setSaving}
            setVisible={setVisible}
            mappedEvents={mappedSmartEvents}
            saving={saving}
            hasSingleDT={hasSingleDT}
          />
        )
      )}
    </>
  );
};
export default React.memo(SmartFormFooter);
