import { useMemo, useCallback, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { TitleWrapper } from './staff.style';
import Table from 'views/containers/table';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import {
  GET_ABBREVIATED_PROVIDERS_LIST,
  GET_PROVIDER_INITIAL_LOAD_DATA,
  GET_PROVIDER_MEMBER
} from 'api/graphql/v2/queries/Providers';
import { DEFAULT_SORT } from 'utils/constants/default';
import React, { useState } from 'react';
import {
  ICalendarFilterData,
  ICalendarFilterOption
} from 'model/calendar/filters';
import { IEditProviderForm, IProvider } from 'model/v2/providers';
import FilterTagList from 'components/calendar/calendarToolbar/FilterTagList';
import { CALENDAR_FILTER_ENUM } from 'utils/constants/calendarFilters';
import { mapProviderTableInfo } from 'utils/mappers/response/staff';
import { PROVIDER_COLUMNS } from 'utils/constants/tables/provider';
import {
  WaitListFilterWrapper,
  WaitListPopOver,
  WaitlistWrapper
} from 'views/components/waitlistPage/waitlist.styled';
import { table_Skeletal_Loader } from 'utils/mappers/carePlans';
import { Skeleton } from 'antd';
import PopOverComp from 'components/popover';
import {
  FilterPageNameEnum,
  savedFilterNameEnum
} from 'components/calendar/calendarToolbar/FilterTagList/form/utils';
import InfiniteScroll from 'react-infinite-scroll-component';

import SideBar from 'components/sidebar/sidebar';
import { ProviderCalendarFiltersAndSearchWrapper } from 'components/calendar/calendarFiltersAndSearch/style';
import WaitListSideDrawer from 'views/components/sideDrawer/waitListSideDrawer';
import useCalendarFilterFetcherHook from 'hooks/calendarFilterFetcherHook';
import { defaultFilterPrefVar } from 'utils/cache/filters';
import { useReactiveVar } from '@apollo/client';
import { Select } from 'api/sharedComponents/reactHookFormComponents';
import { mapWaitListClientAvailabilityFilters } from 'helpers/clientAvailabilityHelper';
import { cloneWithoutTypename } from 'utils/common';
import { UPDATE_PROVIDER } from 'api/graphql/v2/mutations/Providers';
import MainButton from 'views/components/button';
import ProviderFilterLayout from '../filterLayout';
import ClientSearch from '../input/clientInput';
import ProviderSearch from '../input/providerInput';
import { PROVIDER_ROSTER_STATUS_SELECT } from 'utils/constants/tables/staff';
export const searchValueContext = React.createContext('');
export const checkedSavedFilterContext = React.createContext<any>(undefined);

type tableData = {
  providerId: number;
};
interface OptionType {
  displayName: string;
}
export const providerRosterCntx: any = React.createContext(undefined);

const Staff = () => {
  const history = useHistory();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [sidebarData, setSidebarData] = useState();
  const [tableData, setTableData] = useState<tableData[] | []>([]);
  const [list, setList] = useState<OptionType[]>([]);
  const [providerSearch, setProviderSearch] = useState('');
  const [clientSearch, setClientSearch] = useState('');
  const [clientList, setClientList] = useState<OptionType[]>([]);
  const [checkedFilter, setCheckedFilter] = useState('');
  const [checkedSavedFilter, setCheckedSavedFilter] = useState('');
  const [isShowDrawer, setIsShowDrawer] = useState<Boolean>(false);
  const [checkedProviderAvailability, setProviderAvailability] = useState<any>(
    []
  );
  const [pageNo, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [providerAllergies, setProviderAllergies] = useState([]);
  const useDefaultFilter = useReactiveVar(defaultFilterPrefVar);
  const [hasSavedFilters, setHasSavedFilters] = useState(true);
  const [selectTypeData, setSelectTypeData] = useState([]);
  const [calendarFilterData, setCalendarFilterData] = useState<
    ICalendarFilterData
  >();
  const [providerLocations, setProviderLocations] = useState();
  const [clickedFilter, setClickedFilter] = useState('');

  console.log(hasSavedFilters, clickedFilter, 'states for handling search');
  useEffect(() => {
    setCheckedFilter(checkedSavedFilter);
  }, [checkedSavedFilter]);

  const [getProviderInitalLoadContent, { loading: IsLoading }] = useLazyQuery(
    GET_PROVIDER_INITIAL_LOAD_DATA,
    {
      fetchPolicy: 'cache-and-network',
      onCompleted: (data: any) => {
        let {
          petAllergies,
          filterSelections,
          filterList,
          providerLocations
        } = data.getProviderIntialLoadData;
        const allAllergies = petAllergies?.map((item: any) => ({
          value: item.id,
          label: item.value
        }));
        filterList.corticaProgram = filterList?.corticaProgram?.map(
          (data: any) => {
            return {
              ...data,
              title: data.programName
            };
          }
        );
        setProviderAllergies(allAllergies);
        setSelectTypeData(filterSelections);
        console.log(filterList, 'filterList check');
        setCalendarFilterData(filterList);
        setProviderLocations(providerLocations);
      }
    }
  );

  useEffect(() => {
    getProviderInitalLoadContent();
  }, []);

  const {
    defaultFilter,
    savedFilters,
    savedFiltersMap,
    allFilters,
    isSavedFilterLoading,
    useFilterRemovalHook,
    SavedFilter,
    refetch
  } = useCalendarFilterFetcherHook(FilterPageNameEnum.provider, 'no-cache');

  const [additionalFilter, setAdditionalFilter] = useState<any>({
    isFilterApplied: false,
    locations: [],
    startTime: null,
    endTime: null,
    days: []
  });

  const prepareSubmitData = (data: IEditProviderForm) => {
    data = {
      ...data,
      telehealthLinks: data?.telehealthLinks
    };
    delete data.providerCapacity?.rampingPercentage;
    return data;
  };

  const [update] = useMutation(UPDATE_PROVIDER);

  const handleStaffUpdate = useCallback(
    async (event, providerInfo, updateType) => {
      if (providerInfo) {
        let data = prepareSubmitData(providerInfo);
        let provider = cloneWithoutTypename(data);
        provider[updateType] = event;
        if (updateType === 'status') {
          let [activeMap] = PROVIDER_ROSTER_STATUS_SELECT;
          provider.isActive = event === activeMap.value ? true : false;
        }
        setTableData((prevData: tableData[]) => {
          return prevData.map((data: tableData) => {
            if (data.providerId === providerInfo.id) {
              return {
                ...data,
                [updateType]: event
              };
            } else
              return {
                ...data
              };
          });
        });
        const isProviderCapacityUpdateRequired = false;
        delete provider?.clinic?.abbreviation;
        delete provider?.speciality?.title;
        delete provider?.name;
        delete provider?.speciality?.abbreviation;
        provider.corticaPrograms = provider?.corticaPrograms?.map(
          (data: any) => {
            return {
              id: data.id
            };
          }
        );
        await updateProvider(provider, isProviderCapacityUpdateRequired);
      }
    },
    []
  );

  const updateProvider = useCallback(
    async (provider: IProvider, isProviderCapacityUpdateRequired: boolean) => {
      await update({
        variables: {
          data: provider,
          isProviderCapacityUpdateRequired
        },
        refetchQueries: [
          {
            query: GET_PROVIDER_MEMBER,
            variables: {
              id: provider.id
            }
          }
        ]
      })
        .then()
        .catch(error => {
          console.log('Error while saving staff', JSON.stringify(error));
        });
    },
    [update]
  );

  const clearAdditionalFilter = React.useCallback(() => {
    setCheckedFilter('');
    setAdditionalFilter({
      isFilterApplied: false,
      locations: [],
      startTime: null,
      endTime: null,
      days: []
    });
  }, [setCheckedFilter, setAdditionalFilter]);

  const onSearchChange = (e: any) => {
    let [value] = e;
    if (!value?.name) {
      setProviderSearch('');
    } else if (value) {
      setProviderSearch(value.name);
    }
  };

  const onclientSearchChange = (e: any) => {
    let [value] = e;
    if (!value?.name) {
      setClientSearch('');
    } else if (value) {
      setClientSearch(value.name);
    }
  };

  // const [selectedButtonIndex, setSelectedButtonIndex] = useState<number>(-1);

  //The following 4 variables manage state for which options have been checked in dropdowns
  const [checkedClinics, setCheckedClinics] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedPrograms, setCheckedPrograms] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedStatus, setCheckedStatus] = useState<ICalendarFilterOption[]>(
    []
  );
  const [checkedDepartments, setCheckedDepartments] = React.useState<
    ICalendarFilterOption[]
  >([]);
  const [checkedSpecialities, setCheckedSpecialities] = React.useState<
    ICalendarFilterOption[]
  >([]);

  useEffect(() => {
    setPage(1);
    setTableData([]);
    setHasMore(true);
  }, [
    checkedClinics,
    checkedPrograms,
    checkedDepartments,
    checkedSpecialities,
    checkedStatus,
    checkedProviderAvailability,
    clientSearch,
    providerSearch,
    additionalFilter.isFilterApplied
  ]);

  const filterSelectionType: any = {};
  useMemo(() => {
    selectTypeData?.map((selectionType: any) => {
      filterSelectionType[selectionType.typeName] = selectionType.id;
    });
  }, [selectTypeData, filterSelectionType]);

  const handleSavedFilter = useCallback(
    name => {
      const savedFilter = savedFiltersMap.get(name);
      if (!savedFilter) return;
      SavedFilter(
        FilterPageNameEnum.provider,
        {
          setCheckedClinics,
          setCheckedStatus,
          setCheckedPrograms,
          setCheckedSpecialities,
          setProviderAvailability,
          setCheckedDepartments
        },
        setAdditionalFilter,
        setCheckedSavedFilter,
        savedFilterNameEnum.saved,
        savedFilter,
        name
      );
    },
    [savedFiltersMap]
  );
  useEffect(() => {
    if (checkedFilter && checkedFilter !== '') handleSavedFilter(checkedFilter);
  }, [savedFilters]);
  useEffect(() => {
    if (defaultFilter && checkedSavedFilter === '') {
      SavedFilter(
        FilterPageNameEnum.provider,
        {
          setCheckedClinics,
          setCheckedStatus,
          setCheckedPrograms,
          setCheckedSpecialities,
          setProviderAvailability,
          setCheckedDepartments
        },
        setAdditionalFilter,
        setCheckedSavedFilter,
        savedFilterNameEnum.default,
        defaultFilter,
        false,
        defaultFilterPrefVar
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilter, useDefaultFilter]);
  const handleSearchedValues = useCallback(
    list => {
      return list;
    },
    [list]
  );
  const onSetClickedFilter = useCallback(
    label => {
      setClickedFilter(label);
    },
    [setClickedFilter]
  );

  const handleStaffRemoveFilter = useFilterRemovalHook(
    setCheckedSavedFilter,
    CALENDAR_FILTER_ENUM,
    {
      setCheckedClinics,
      setCheckedPrograms,
      setProviderAvailability,
      setCheckedSpecialities,
      setCheckedStatus,
      setCheckedDepartments
    }
  );

  /* 
  The current strategy is a pagination approach leveraging server-side filtering, 
  passing the filters that users select to the query on the backend, and returning the relevent data.
  */
  let checkedClinicsIds = checkedClinics.map(clinic => clinic.value);
  let checkedDepartmentIds = checkedDepartments.map(
    department => department.value
  );
  let checkedspecialtiesIds = checkedSpecialities.map(
    specialty => specialty.value
  );

  let checkedStatusLabel = checkedStatus?.map(status => status.label);
  let checkedProgramIds = checkedPrograms.map(status => status.label);
  const loaderView = table_Skeletal_Loader(Skeleton, true, tableData);
  const { loading: providerDataLoading, data: allProviderData } = useQuery(
    GET_ABBREVIATED_PROVIDERS_LIST,
    {
      fetchPolicy: 'network-only',
      variables: {
        from: pageNo * 25 - 25,
        size: 25,
        search: providerSearch ? providerSearch.trimEnd() : null,
        sort: DEFAULT_SORT,
        clinic: [...checkedClinicsIds],
        specialty: [...checkedspecialtiesIds],
        status: [...checkedStatusLabel!],
        corticaProgram: [...checkedProgramIds],
        clientSearch: clientSearch,
        department: [...checkedDepartmentIds],
        additionalFilter: mapWaitListClientAvailabilityFilters(
          checkedProviderAvailability,
          additionalFilter,
          providerLocations
        )
      },
      onCompleted: data => {
        const newProviders = mapProviderTableInfo(
          data?.providerRoaster?.providers,
          Select,
          providerAllergies,
          handleStaffUpdate
        );
        setTableData(prevPatients => {
          return pageNo > 1 ? [...prevPatients, ...newProviders] : newProviders;
        });
        if (newProviders.length < 25) {
          setHasMore(false);
        }
      },
      onError: error => {
        console.log('Error when querying to fetch list of providers:', error);
      }
    }
  );

  const addStaff = useCallback(() => {
    history.push({
      pathname: '/providers/new',
      state: {
        title: 'Add Staff Info'
      }
    });
  }, [history]);

  const handleNoteAdded = (clientId: string, addedNote: any) => {
    setTableData((prevData: any) => {
      return prevData.map((item: any) => {
        if (item.providerId === clientId) {
          return {
            ...item,
            notes: [...item.notes, addedNote],
            notesCount: item.notesCount + 1
          };
        }
        return item;
      });
    });
  };

  const staffFilters = {
    clinics: checkedClinics,
    specialities: checkedSpecialities,
    departments: checkedDepartments,
    locations: [],
    programs: checkedPrograms,
    status: checkedStatus,
    savedFilterName: ''
  };

  const clearAllFilters = useCallback(() => {
    setCheckedClinics([]);
    setCheckedPrograms([]);
    setCheckedSpecialities([]);
    setProviderAvailability([]);
    setCheckedStatus([]);
    setCheckedDepartments([]);
  }, []);

  const navigateToProviderProfile = (rowId: number) => {
    !providerDataLoading && window.open('/providers/' + rowId, '_blank');
  };

  //Clear all filters
  const clearAllSelectedFilters = useCallback(() => {
    setCheckedFilter('');
    clearAllFilters();
  }, []);

  //Infinity scroll logic to get next set of results
  const fetchMoreData = () => {
    if (providerDataLoading) {
      return;
    } else {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <div>
      <TitleWrapper>
        <div className="provider-title-outer-wrap">
          <div className="inner-wrap provider-title">
            <h1>Provider Roster</h1>
          </div>
          <MainButton
            className="Add-Staff-btn"
            title={'Add Staff'}
            onClick={addStaff}
          />
        </div>
      </TitleWrapper>
      <providerRosterCntx.Provider value={setCheckedSavedFilter}>
        <WaitListFilterWrapper isStaff={true}>
          <div className="actions">
            <div className="dropdown-wrapper provider-dropdown-wrapper"></div>
            <ProviderFilterLayout
              isLoading={IsLoading}
              FilterData={calendarFilterData!}
              checkedClinics={checkedClinics}
              setCheckedClinics={setCheckedClinics}
              checkedPrograms={checkedPrograms}
              setCheckedPrograms={setCheckedPrograms}
              checkedStatus={checkedStatus}
              setCheckedStatus={setCheckedStatus}
              checkedDepartments={checkedDepartments}
              setCheckedDepartments={setCheckedDepartments}
              checkedSpecialities={checkedSpecialities}
              setCheckedSpecialities={setCheckedSpecialities}
              setIsShowDrawer={setIsShowDrawer}
            >
              {() => {
                let ClientSearchWrapper = (
                  <ProviderCalendarFiltersAndSearchWrapper isStaffPage={true}>
                    <ClientSearch
                      clientSearch={clientSearch}
                      onclientSearchChange={onclientSearchChange}
                      setClientList={setClientList}
                      clientList={clientList}
                    />
                  </ProviderCalendarFiltersAndSearchWrapper>
                );
                let ProviderSearchWrapper = (
                  <ProviderCalendarFiltersAndSearchWrapper isStaffPage={true}>
                    <ProviderSearch
                      providerSearch={providerSearch}
                      onSearchChange={onSearchChange}
                      setList={setList}
                      list={list}
                    />
                  </ProviderCalendarFiltersAndSearchWrapper>
                );
                return { ClientSearchWrapper, ProviderSearchWrapper };
              }}
            </ProviderFilterLayout>
          </div>
        </WaitListFilterWrapper>
        <WaitlistWrapper isStaff={true}>
          <div className="inner-wrap staff-filters">
            <FilterTagList
              filters={staffFilters}
              refetch={refetch}
              onRemoveFilter={handleStaffRemoveFilter}
              showSavedIcon={true}
              additionalFilter={additionalFilter}
              clearAdditionalFilter={clearAdditionalFilter}
              checkedFilter={checkedFilter}
              setCheckedSavedFilter={setCheckedSavedFilter}
              pageName={FilterPageNameEnum.provider}
            />
          </div>
          <div className="inner-wrap provider-wrap">
            <InfiniteScroll
              dataLength={tableData.length}
              next={fetchMoreData}
              hasMore={hasMore}
              className="infinite-scroll-component"
              endMessage={
                <p style={{ textAlign: 'center' }}>No more providers</p>
              }
              loader={''}
            >
              <Table
                className="custom-table-class"
                columns={PROVIDER_COLUMNS(
                  providerDataLoading,
                  PopOverComp,
                  WaitListPopOver,
                  setShowSidebar,
                  setSidebarData,
                  navigateToProviderProfile,
                  handleStaffUpdate,
                  providerAllergies
                )}
                data={providerDataLoading ? loaderView : tableData}
                total={
                  providerDataLoading
                    ? 6
                    : allProviderData?.providerRoaster?.count
                }
                clearAllSelectedFilters={clearAllSelectedFilters}
                page={pageNo}
                pagination={false}
                onPageChangeCallback={() => {}}
                onRowSelect={() => {}}
                rowClassName={() => {
                  return 'custom-provider-row';
                }}
              />
            </InfiniteScroll>

            {showSidebar ? (
              <SideBar
                show={showSidebar}
                close={() => setShowSidebar(!setShowSidebar)}
                sidebarData={sidebarData}
                setSidebarData={setSidebarData}
                onNoteAdded={handleNoteAdded}
                isStaffNotes={true}
                activeTab={'notes'}
              />
            ) : (
              <></>
            )}
          </div>
          {isShowDrawer && (
            <WaitListSideDrawer
              isShowDrawer={isShowDrawer}
              refetch={refetch}
              setAdditionalFilter={setAdditionalFilter}
              waitListDataLoading={IsLoading}
              Skeleton={Skeleton}
              additionalFilter={additionalFilter}
              locations={providerLocations}
              location={location}
              setIsShowDrawer={setIsShowDrawer}
              selectSavedFilter={handleSavedFilter}
              savedFilter={checkedSavedFilter}
              handleSearchedValues={handleSearchedValues}
              setHasData={setHasSavedFilters}
              setClickedFilter={onSetClickedFilter}
              savedFilters={savedFilters}
              defaultFilter={defaultFilter}
              isSavedFilterLoading={isSavedFilterLoading && IsLoading}
              pageName={FilterPageNameEnum.provider}
              setCheckedFilter={setCheckedFilter}
              checkedFilter={checkedFilter}
              clearAllFilters={clearAllFilters}
              filterSelectionType={filterSelectionType}
              allFilters={allFilters}
            />
          )}
        </WaitlistWrapper>
      </providerRosterCntx.Provider>
    </div>
  );
};
export default Staff;
