import React, { Dispatch, SetStateAction } from 'react';
import { calendarGroup, IPlannerGroup } from 'model/calendar/groups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { CloseOutlined } from '@ant-design/icons';
import { getAttendeeBulletClassName } from 'helpers/calendarUIHelper';
import { useReactiveVar } from '@apollo/client';
import { plannerCalendarPrefViewVar, PlannerView } from 'utils/cache/calendar';
import { GET_CLIENT_BASIC_DATA } from 'api/graphql/v2/queries/Clients';
import { useQuery } from 'react-apollo';
import { BasicClientProfile } from 'model/client';
import moment from 'moment';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';

interface Props {
  cloneGroup: boolean;
  group: IPlannerGroup | undefined;
  isShown: boolean;
  setRemovedGroups: Dispatch<SetStateAction<number[]>>;
  removedGroups: number[];
  showFullGroup: boolean;
  handleGroupRemoval?: () => void;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

const Group: React.FC<Props> = ({
  cloneGroup,
  group,
  isShown,
  showFullGroup,
  handleGroupRemoval,
  dragHandleProps
}: Props) => {
  const calendarView = useReactiveVar(plannerCalendarPrefViewVar);

  const isClient = group?.attendeeType === calendarGroup.client;

  const [clientData, setClientData] = React.useState<BasicClientProfile>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  let idForRequest: string | number | undefined;
  if (group?.id && typeof group.id === 'string') {
    const idString = group.id.toString();
    const idStringList = idString.split(':');
    idForRequest = parseInt(idStringList[0]);
  } else {
    idForRequest = group?.id;
  }

  const formatClientDob = (date: string) => {
    const result = moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY');
    return result;
  };
  // const loadClient = (idForRequest !== undefined) && isClient;

  const { loading: loadingClient } = useQuery(GET_CLIENT_BASIC_DATA, {
    fetchPolicy: 'no-cache',
    variables: { id: Number(idForRequest) },
    onCompleted: data => {
      setClientData(data.client);
      setIsLoading(loadingClient);
    }
  });

  const handleDisplayName = (
    displayName: string | undefined
  ): string | undefined => {
    const MAX_CHARS: number = 30;
    let splittedNames: string[] | undefined = displayName
      ?.replace(',', '')
      .split(' ');

    if (splittedNames === undefined) return '';

    // 3: for spaces
    let toleratedLastNameChars: number =
      MAX_CHARS -
      ((splittedNames[0] === undefined ? 0 : splittedNames[0].length) +
        (splittedNames[2] === undefined ? 0 : splittedNames[2].length) +
        (splittedNames[3] === undefined ? 0 : splittedNames[3].length) +
        3);

    if (toleratedLastNameChars > splittedNames[1].length) return displayName;

    let lastName: string = splittedNames[1].substr(
      0,
      toleratedLastNameChars - 4
    );
    lastName = lastName.length === 0 ? ', ' : ' ' + lastName + '..., ';

    return (
      splittedNames[0] +
      lastName +
      splittedNames[2] +
      (splittedNames[3] === undefined ? '' : ' ' + splittedNames[3])
    );
  };
  return (
    <div className={cloneGroup ? 'custom-group clone' : 'custom-group'}>
      <div className="group-identifier">
        {isShown && !cloneGroup ? (
          <FontAwesomeIcon icon={faEllipsisV} className="drag-icon" />
        ) : (
          <div
            style={{
              width:
                group?.attendeeType === calendarGroup.client ? '10px' : '16px'
            }}
          />
        )}
        {showFullGroup && (
          <div
            className={getAttendeeBulletClassName(group)}
            {...dragHandleProps}
          />
        )}
        {showFullGroup && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div {...dragHandleProps}>
              <p className="title">
                {showFullGroup
                  ? calendarView === PlannerView.plannerDay
                    ? handleDisplayName(group?.displayName)
                    : group?.displayName
                  : ''}
              </p>
            </div>
            {isClient && clientData && !isLoading && (
              <div className="client-case-render">
                <div className="basic-data-shell">
                  <div className="basic-info">
                    <div className="basic-info-block">
                      <small style={{ lineHeight: '12px' }}>
                        Athena ID: {clientData.athenaId}
                      </small>
                    </div>
                    <div className="basic-info-block">
                      <small style={{ lineHeight: '12px' }}>
                        CR ID: {clientData.crId}
                      </small>
                    </div>
                  </div>
                  <div className="dob-row" style={{ lineHeight: '13px' }}>
                    <small>
                      DOB: {formatClientDob(clientData.profile.dob)}
                    </small>
                  </div>
                </div>
                {calendarView === PlannerView.plannerWeek && (
                  <div className="advance-info-shell">
                    <div className="advance-info-shell-child">
                      <small style={{ lineHeight: '13px' }}>
                        Legal Guardian(s):{' '}
                        {clientData.profile.legalGuardianFirstName}{' '}
                        {clientData.profile.legalGuardianLastName}
                      </small>
                    </div>
                    <div className="advance-info-shell-child">
                      <small style={{ lineHeight: '12px' }}>
                        <div className="spaced-text">Contact Email: </div>
                        <div className="spaced-text">
                          {clientData.profile.personalEmail
                            ? clientData.profile.personalEmail
                            : 'N/A'}
                        </div>
                      </small>
                    </div>
                    <div className="advance-info-shell-child">
                      <small style={{ lineHeight: '12px' }}>
                        <div className="spaced-text">Mother's Email: </div>
                        <div className="spaced-text">
                          {clientData.profile.motherEmail
                            ? clientData.profile.motherEmail
                            : 'N/A'}
                        </div>
                      </small>
                    </div>
                    <div className="advance-info-shell-child">
                      <small style={{ lineHeight: '12px' }}>
                        <div className="spaced-text">Father's Email: </div>
                        <div className="spaced-text">
                          {clientData.profile.fatherEmail
                            ? clientData.profile.fatherEmail
                            : 'N/A'}
                        </div>
                      </small>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!isClient && !isLoading && (
              <div className="client-case-render">
                <div className="advance-info-shell">
                  <small className="advance-info-shell-child">
                    Programs:{' '}
                    {group?.corticaPrograms
                      ?.map(program => program.programName)
                      .join(', ')}
                  </small>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      {showFullGroup && (
        <button
          className={`ui icon button
        ${cloneGroup ? 'clone-close-button' : 'close-button'}`}
          onClick={handleGroupRemoval}
        >
          <CloseOutlined />
        </button>
      )}
    </div>
  );
};

export default React.memo(Group);
