import React, { Dispatch, SetStateAction, useEffect } from 'react';
import AppointmentView from './AppointmentView';
import { CalendarView } from 'utils/cache/calendar';
import moment from 'moment';
import {
  ADMIN_DEFAULT_CANCEL_REASON,
  APPOINTMENT_CATEGORY,
  CLIENT_FREQUENCY_PREFERENCE,
  CLINIC_PROVIDERS,
  EVENT_CANCEL_OPTIONS,
  EVENT_CATEGORY,
  PAYMENT_METHODS,
  PROVIDER_FILTER_CLINICS_TYPE_VALUES,
  SCHEDULE_TYPE_LIST
} from 'utils/constants/lists';
import { ICalendarResource } from 'model/calendar/filters';
import { DEFAULT_DURATION } from 'utils/constants/default';
import {
  IFormEvent,
  ISmartForm,
  SidebarState,
  IClient,
  IFullAppointmentType
} from 'model/v2';
import { EventType } from 'model/calendar/events';
import { EVENT_TYPES } from 'utils/constants/appointmentsTypes';
import AppointmentForm from './AppointmentForm/AppointmentForm';
import AppointmentObserver from './AppointmentObserver';
import { IEditAppointmentPopup } from 'model';

export const DEFAULT_SESSION_DURATION = {
  MIN: 120,
  MAX: 300
};

export const SIDEBAR_ACTIONS = {
  VIEW: 'VIEW',
  NEW: 'New',
  EDIT: 'Edit',
  OBSERVATION_VIEW: 'ObserverView',
  SUBSTITUTE: 'Substitute',
  FIND_MAKEUP: 'FindMakeup',
  POTENIAL_RESULTS: 'Potenial Results'
};

export const isAdmin = (event: IFormEvent | undefined) =>
  event?.appointmentType?.eventType?.name === EVENT_TYPES.Admin ||
  event?.type === EVENT_CATEGORY[1].value ||
  event?.eventTypeName === EVENT_TYPES.Admin;

interface Props {
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  view: CalendarView;
  date: Date;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<SetStateAction<ICalendarResource[]>>;
  openSidebar: (sidebarState: SidebarState) => void;
  visible: boolean;
  apptTypes: IFullAppointmentType[];
  loadingApptTypes: boolean;
  adminTypes: IFullAppointmentType[];
  isDTAppt?: Boolean;
  parent_Type_ID?: number;
  subType_Id?: number;
}
const AppointmentSidebar: React.FC<Props> = ({
  setVisible,
  sidebarState,
  paginationArray,
  setPaginationArray,
  openSidebar,
  visible,
  apptTypes,
  loadingApptTypes,
  adminTypes,
  isDTAppt,
  parent_Type_ID,
  subType_Id
}: Props) => {
  const [sidebarS, setSidebarS] = React.useState<SidebarState>(sidebarState);
  const [isAddObservationMode, setIsAddObservationMode] = React.useState<
    boolean
  >(false);
  const [isSideBarOpened, setIsSideBarOpened] = React.useState(true);
  useEffect(() => {
    if (sidebarState.isRedirected) {
      setIsAddObservationMode(true);
    } else {
      setIsAddObservationMode(false);
    }
    setIsSideBarOpened(visible);
  }, [isSideBarOpened, sidebarState, visible]);

  const changeFormHandler = React.useCallback(
    (event: IFormEvent) => {
      event.duration = isAdmin(event)
        ? DEFAULT_DURATION.ADMIN
        : DEFAULT_DURATION.CLIENT;

      if (event.startDate) {
        event.endDate = moment(event.startDate)
          .clone()
          .add(event.duration, 'minutes');
      }
      event.appType = undefined;
      event.appSubType = undefined;
      setSidebarS({
        event,
        action: sidebarS.action
      });
    },
    [setSidebarS, sidebarS]
  );

  React.useMemo(() => {
    setSidebarS(sidebarState);
  }, [sidebarState]);

  return sidebarState?.action === SIDEBAR_ACTIONS.VIEW ? (
    <AppointmentView
      setVisible={setVisible}
      sidebarState={sidebarS}
      setPaginationArray={setPaginationArray}
      paginationArray={paginationArray}
      setIsAddObservationMode={setIsAddObservationMode}
      isAddObservationMode={isAddObservationMode}
    />
  ) : sidebarState?.action === SIDEBAR_ACTIONS.OBSERVATION_VIEW ? (
    <AppointmentObserver
      setVisible={setVisible}
      sidebarState={sidebarS}
      openSidebar={openSidebar}
      setIsAddObservationMode={setIsAddObservationMode}
    />
  ) : (
    <AppointmentForm
      setVisible={setVisible}
      sidebarState={sidebarS}
      changeFormHandler={changeFormHandler}
      setPaginationArray={setPaginationArray}
      paginationArray={paginationArray}
      visible={isSideBarOpened}
      apptTypes={apptTypes}
      loadingApptTypes={loadingApptTypes}
      adminTypes={adminTypes}
      isDTAppt={isDTAppt}
      subType_Id={subType_Id}
      parent_Type_ID={parent_Type_ID}
    />
  );
};

export default React.memo(AppointmentSidebar);

// export const initValuesOfAvailableSlots = (event: IFormEvent | undefined) => {
//   console.log('Inside initValueOfEvalslots function');
//   // const isAdminType = appointmentCategory === APPOINTMENT_CATEGORY[3].value;
//   // const isAdminEvent = isAdmin(event)
//   //   ? (EVENT_CATEGORY[1].value as EventType)
//   //   : (EVENT_CATEGORY[0].value as EventType);
//   // const isEventType = event?.type ? event?.type : isAdminEvent;
//   return {
//     // ...event,
//     // apptCategory: event?.appointmentType?.eventType?.name
//     //   ? `Appointment Category: ${event?.appointmentType?.eventType?.name}`
//     //   : appointmentCategory
//     //   ? `Appointment Category: ${appointmentCategory}`
//     //   : undefined,
//     // apptCategoryType:
//     //   event?.appointmentType?.eventType?.name || appointmentCategory,
//     // client: event?.client || currClient,
//     // appType: event?.appointmentType?.parent?.id || event?.appointmentType?.id,
//     // appTypeLabel: null,
//     // eventTypeName:
//     //   appointmentCategory === APPOINTMENT_CATEGORY[3].value
//     //     ? EVENT_CATEGORY[1].value
//     //     : event?.eventTypeName ||
//     //       event?.appointmentType?.eventType?.name ||
//     //       event?.appointmentType?.parent?.eventType?.name,
//     // appSubType: event?.appointmentType?.parent?.id
//     //   ? event?.appointmentType?.id
//     //   : undefined,
//     selectedDay: event?.selectedDay
//       ? moment(event.selectedDay)
//       : event?.startDate
//       ? moment(event?.startDate)
//       : undefined,
//     recurrencePattern: {
//       ...event?.recurrencePattern,
//       recurringUntil: event?.recurrencePattern?.recurringUntil
//         ? moment(event?.recurrencePattern?.recurringUntil)
//         : moment(event?.startDate)
//             .add(4, 'weeks')
//             .endOf('year')
//             .endOf('day')
//     },
//     startDate: event?.startDate ? moment(event.startDate) : undefined,
//     endDate: event?.endDate ? moment(event.endDate) : undefined,
//     // duration: event?.duration || 30,
//     // type: isAdminType ? (EVENT_CATEGORY[1].value as EventType) : isEventType,
//     smart: false,
//     clinicPreference: event?.clinicPreference || CLINIC_PROVIDERS[0].id,
//     telehealthLink: event?.telehealthLink,
//     scheduleType: event?.scheduleType
//       ? event.scheduleType
//       : event?.recurrencePattern?.recurringEvery
//       ? SCHEDULE_TYPE_LIST[1].id
//       : SCHEDULE_TYPE_LIST[0].id,
//     isPendingConfirmation: event?.isPendingConfirmation || false,
//     recurrent: EVENT_CANCEL_OPTIONS(true)[0].value,
//     cancelReason: isAdmin(event) ? ADMIN_DEFAULT_CANCEL_REASON : null,
//     cancelNote: '',
//     dirtyEdit: false,
//     selectedOpening: null,
//     recurringDaysFlag: undefined,
//     address: {
//       ...event?.address,
//       id: Number(event?.address?.id)
//     }
//     // paymentMethod: isAba
//     //   ? event?.paymentMethod || PAYMENT_METHODS[0].id
//     //   : undefined
//   };
// };

export const initValues = (
  event: IFormEvent | undefined,
  appointmentCategory?: string,
  isAba?: boolean,
  currClient?: IClient,
  apptTypeTitle?: string | undefined
): IFormEvent => {
  const isAdminType = appointmentCategory === APPOINTMENT_CATEGORY[3].value;
  const isAdminEvent = isAdmin(event)
    ? (EVENT_CATEGORY[1].value as EventType)
    : (EVENT_CATEGORY[0].value as EventType);
  const isEventType = event?.type ? event?.type : isAdminEvent;
  return {
    ...event,
    apptCategory: event?.appointmentType?.eventType?.name
      ? `Appointment Category: ${event?.appointmentType?.eventType?.name}`
      : appointmentCategory
      ? `Appointment Category: ${appointmentCategory}`
      : undefined,
    apptCategoryType:
      event?.appointmentType?.eventType?.name || appointmentCategory,
    client: event?.client || currClient,
    appType:
      (event?.isDTAppt && event?.parent_Type_ID) ||
      (event?.isABAAppt && event?.parent_Type_ID) ||
      event?.appointmentType?.parent?.id ||
      event?.appointmentType?.id,
    appTypeLabel: apptTypeTitle ? `Appointment Type: ${apptTypeTitle}` : null,
    eventTypeName:
      appointmentCategory === APPOINTMENT_CATEGORY[3].value
        ? EVENT_CATEGORY[1].value
        : event?.eventTypeName ||
          event?.appointmentType?.eventType?.name ||
          event?.appointmentType?.parent?.eventType?.name,
    appSubType: event?.appointmentType?.parent?.id
      ? event?.appointmentType?.id
      : event?.isDTAppt
      ? event?.subType_Id
        ? event?.subType_Id
        : undefined
      : undefined,
    selectedDay: event?.selectedDay
      ? moment(event.selectedDay)
      : event?.startDate
      ? moment(event?.startDate)
      : undefined,
    recurrencePattern: {
      ...event?.recurrencePattern,
      recurringUntil: event?.recurrencePattern?.recurringUntil
        ? moment(event?.recurrencePattern?.recurringUntil)
        : moment('2025-01-04')
    },
    startDate: event?.startDate ? moment(event.startDate) : undefined,
    endDate: event?.endDate ? moment(event.endDate) : undefined,
    duration: event?.duration || 30,
    type: isAdminType ? (EVENT_CATEGORY[1].value as EventType) : isEventType,
    smart: false,
    clinicPreference: event?.clinicPreference || CLINIC_PROVIDERS[0].id,
    telehealthLink: event?.telehealthLink
      ? event.telehealthLink
      : event?.provider?.telehealthLinks?.length === 1
      ? event?.provider?.telehealthLinks[0]
      : undefined,
    scheduleType: event?.scheduleType
      ? event.scheduleType
      : event?.recurrencePattern?.recurringEvery
      ? SCHEDULE_TYPE_LIST[1].id
      : SCHEDULE_TYPE_LIST[0].id,
    isPendingConfirmation: event?.isPendingConfirmation || false,
    recurrent: EVENT_CANCEL_OPTIONS(true)[0].value,
    cancelReason: isAdmin(event) ? ADMIN_DEFAULT_CANCEL_REASON : null,
    cancelNote: '',
    dirtyEdit: false,
    selectedOpening: null,
    recurringDaysFlag: undefined,
    address: {
      ...event?.address,
      id: Number(event?.address?.id)
    },
    paymentMethod: isAba
      ? event?.paymentMethod || PAYMENT_METHODS[0].id
      : undefined,
    driveTimeSourceAddr: event?.driveTimeSourceAddr
      ? event?.driveTimeSourceAddr
      : undefined,
    driveTimeDesteAddr: event?.driveTimeDesteAddr
      ? event?.driveTimeDesteAddr
      : undefined,
    driveTimeMileage:
      event?.driveTimeMileage !== undefined && event?.driveTimeMileage !== null
        ? event?.driveTimeMileage
        : undefined,
    isFirstOrLastDriveToNonClinic: event?.isFirstOrLastDriveToNonClinic
      ? event?.isFirstOrLastDriveToNonClinic
      : undefined,
    reimbursableMileage:
      event?.reimbursableMileage !== undefined &&
      event?.reimbursableMileage !== null
        ? event?.reimbursableMileage
        : undefined
  };
};

export const initSmartValues = (
  event?: IFormEvent,
  isAba?: boolean,
  isDT?: boolean,
  appointmentCategory?: string,
  currClient?: IClient,
  isSingleAppt?: boolean,
  apptTypeTitle?: string | undefined
): ISmartForm => {
  const initEvent = initValues(
    event,
    appointmentCategory,
    isAba,
    currClient,
    apptTypeTitle
  );
  return {
    ...initEvent,
    smart: true,

    startDate: !isSingleAppt ? moment(new Date()) : undefined,
    endDate: moment('2025-01-04'),
    clinic: event?.client?.clinic || currClient?.clinic,
    providerFilter: {
      clinicType: PROVIDER_FILTER_CLINICS_TYPE_VALUES.clientNetwork
    },
    clientAvailabilityDayFilter: undefined,
    aba: isAba
      ? {
          sessionDuration: {
            min: DEFAULT_SESSION_DURATION.MIN,
            max: DEFAULT_SESSION_DURATION.MAX
          },
          offsiteWeekly: '00:00',
          inClinicWeekly: '00:00',
          clientFrequencyPreference: CLIENT_FREQUENCY_PREFERENCE[0].id,
          maximumProviders: 3,
          clientDailyHours: 8
        }
      : undefined,
    dt: isDT
      ? {
          sessionDuration: 45,
          weeklySessions: event?.no_of_session ? event?.no_of_session : 1,
          clientFrequencyPreference: CLIENT_FREQUENCY_PREFERENCE[0].id,
          maximumProviders: 1,
          clientDailyHours: 8
        }
      : undefined
  };
};

export const initDriveTimeValues = (
  event: IFormEvent | undefined
): IEditAppointmentPopup => {
  return {
    appType: event?.appointmentType?.parent?.id || event?.appointmentType?.id,
    driveTimeSourceAddr: event?.driveTimeSourceAddr
      ? event?.driveTimeSourceAddr
      : undefined,
    driveTimeDesteAddr: event?.driveTimeDesteAddr
      ? event?.driveTimeDesteAddr
      : undefined,
    driveTimeMileage:
      event?.driveTimeMileage !== undefined && event?.driveTimeMileage !== null
        ? event?.driveTimeMileage
        : undefined,
    isFirstOrLastDriveToNonClinic: event?.isFirstOrLastDriveToNonClinic
      ? event?.isFirstOrLastDriveToNonClinic
      : undefined,
    reimbursableMileage:
      event?.reimbursableMileage !== undefined &&
      event?.reimbursableMileage !== null
        ? event?.reimbursableMileage
        : undefined
  };
};
