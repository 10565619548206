import { IEventType } from 'model/eventType';
import { IClient } from 'model/client';

import { Moment } from 'moment';
import { calendarGroup } from './groups';
import {
  IEvent,
  IFullAppointmentType,
  IRecurrencePattern,
  IClinic
} from 'model/v2';

export interface ICardEvent {
  id: string;
  subject: string;
  startTime: Moment;
  endTime: Moment;
  status: Status;
  locationType: string;
  masterID?: string;
  locationCategory?: string;
  appointmentType: IAppointment;
  clinic: IClinic;
  therapist: ITherapist;
  room: IRoom;
  type: EventType;
  client: IClient;
  isPendingConfirmation: boolean;
  recurringEvery: number;
  crStatus: number;
  athenaStatus: number;
}

export interface ICalendarEventsFetcher {
  cardEvents: ICardEvent;
  appointmentTypes: IAppointmentType[];
  appointmentABATypes: IAppointmentType[];
  adminAppointmentTypes: IAdminAppointment[];
}

export interface IAllAppointmentTypes {
  appointmentTypes: IAppointmentType[];
  appointmentABATypes: IAppointmentType[];
  adminAppointmentTypes: IAdminAppointment[];
}

export interface IAppointment {
  id: number;
  isClinical: boolean;
  subType: string;
  title: string;
}
export interface IAppointmentTypeEvent extends IAppointment {
  subType: string;
}
export interface IAppointmentType extends IAppointment {
  id: number;
  title: string;
  headerColor: string;
  backgroundColor: string;
  eventType: IEventType;
  subTypes: ISubType;
}
export interface IAdminAppointment {
  id: number;
  value: string;
}

export interface ISubType {
  id: string;
  title: string;
  athenaType: string;
  crType: string;
  superType: string;
  duration: number;
}

export interface ITherapist {
  id: string;
  name: string;
}
export interface IRoom {
  roomName: string;
}

export interface ICalendarEvent extends IEvent {
  clinics?: any;
  start?: Date;
  end?: Date;
  startTime?: Moment;
  endTime?: Moment;
  resourceId?: string;
  title?: string;
  desc?: IEventDesc;
  groupType: calendarGroup;
  calendarID?: string;
  canMove?: boolean;
  baseId?: string;
  color?: string;
  plannerWeekStartTime?: Moment;
  plannerWeekEndTime?: Moment;
  observationsCount?: number;
  evalSlotLocation?: any;
  isDelete?: boolean;
  makeupSessionEventId?: number;
}

export interface IPhantomEvent {
  resourceId?: string;
  baseId?: string;
  title?: string;
  observationsCount?: number;
  appointmentType?: IFullAppointmentType;
  groupType?: calendarGroup;
  calendarID?: string;
  canMove?: boolean;
  startTime?: Moment;
  endTime?: Moment;
  type?: EventType;
  recurrencePattern?: IRecurrencePattern;
  status?: string;
  startDate?: Moment;
  plannerWeekStartTime?: Moment;
  plannerWeekEndTime?: Moment;
  locationType?: string;
  start?: Date;
  end?: Date;
  clinic?: IClinic;
}

export interface IEventDesc {
  main: string;
  extras: string[];
}

export interface EventSyncStatus {
  eventId?: number;
  crStatus?: number;
  athenaStatus?: number;
  status?: string;
}
export enum EventType {
  clientEvent = 'clientEvent',
  adminEvent = 'adminEvent',
  unAvailable = 'na',
  phantomClientEvent = 'phantomClientEvent',
  phantomAdminEvent = 'phantomAdminEvent',
  availability = 'availability',
  observation = 'observation',
  phantomObserverEvent = 'phantomObserverEvent',
  evalSlot = 'evalSlot'
}

export enum Status {
  scheduledRecurring = 'Scheduled - Recurring',
  scheduledOneTime = 'Scheduled - One Time',
  canceled = 'canceled',
  rescheduled = 'rescheduled',
  pending = 'pending',
  scheduled = 'scheduled',
  invisible = 'invisible',
  needsReschedule = 'needsReschedule',
  deleted = 'deleted'
}
