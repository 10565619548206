import gql from 'graphql-tag';
import { clinicFragments } from './Clinics';
import { addressFragments } from './Address';
import { providerFragments } from './Providers';
import { recurrencePatternFragments } from './RecurrencePattern';
import { appointmentTypesFragments } from './AppointmentTypes';

export const simpleEvent = gql`
  fragment Event on Event {
    id
    athenaId
    athenaStatus
    crId
    authorizedTherapyId
    crExecutionId
    crStatus
    clinicPreference
    instanceIndex
    isPendingConfirmation
    startDate
    endDate
    duration
    locationType
    locationCategory
    paymentMethod
    status
    subject
    telehealthLink
  }
`;

export const eventsFragments = {
  providerEvent: gql`
    fragment ProviderEvent on Event {
      ...Event
      appointmentType {
        ...AppointmentTypeWithParent
      }
      client {
        id
        name
      }
      clinic {
        id
        name
        abbreviation
      }
      recurrencePattern {
        recurringUntil
      }
    }
    ${appointmentTypesFragments.appointmentTypeWithParent}
    ${simpleEvent}
  `,
  EventInput: gql`
    fragment EventInput on Event {
      id
      clinicPreference
      isPendingConfirmation
      startDate
      endDate
      duration
      locationType
      locationCategory
      paymentMethod
      telehealthLink
      note {
        id
        note
      }
      address {
        ...AddressData
      }
      recurrencePattern {
        ...RecurrencePatternData
      }
      appointmentType {
        id
      }
      client {
        id
      }
      provider {
        id
      }
      clinic {
        id
      }
      room {
        id
      }
    }
    ${addressFragments.addressData}
    ${recurrencePatternFragments.recurrencePattern}
  `,
  eventData: gql`
    fragment EventData on Event {
      id
      master {
        id
      }
      subject
      startDate
      endDate
      duration
      status
      locationType
      locationCategory
      clinic {
        id
        name
        abbreviation
      }
      appointmentType {
        ...AppointmentTypeWithParent
      }
      room {
        id
        name
        email
      }
      provider {
        id
        firstName
        lastName
      }
      recurrencePattern {
        ...RecurrencePatternData
      }
      clinicPreference
      paymentMethod
      locationCategory
      locationType
      type
    }
    ${appointmentTypesFragments.appointmentTypeWithParent}
    ${recurrencePatternFragments.recurrencePattern}
  `,
  calendarEventData: gql`
    fragment CalendarEventData on Event {
      id
      subject
      startDate
      endDate
      status
      locationType
      isDelete
      master {
        id
      }
      address {
        ...AddressData
      }
      locationCategory
      recurrencePattern {
        id
        recurringEvery
      }
      crStatus
      athenaStatus
      isPendingConfirmation

      appointmentType {
        ...AppointmentTypeWithParent
      }
      client {
        id
        name
        firstName
        lastName
        clinic {
          id
          abbreviation
        }
        __typename @skip(if: true)
      }
      clinic {
        id
        name
        abbreviation
        __typename
      }
      provider {
        id
        name
        lastName
        firstName
        speciality {
          id
          abbreviation
        }
        clinic {
          id
          abbreviation
        }
        __typename
      }
      room {
        id
        name
        __typename
      }
      paymentMethod
      type
      isObservation
      leadEvent {
        id
        provider {
          id
          name
          lastName
          firstName
        }
      }
      observationsCount
      observations {
        id
        provider {
          ...Provider
        }
      }
      __typename @skip(if: true)
    }
    ${providerFragments.provider}
    ${addressFragments.addressData}
    ${appointmentTypesFragments.appointmentTypeWithParent}
  `,
  calendarPlannerEventData: gql`
    fragment calendarPlannerEventData on Event {
      id
      subject
      startDate
      endDate
      duration
      status
      locationType
      telehealthLink
      clinicPreference
      isDelete
      master {
        id
      }
      address {
        ...AddressData
      }
      locationCategory
      recurrencePattern {
        id
        recurringEvery
      }
      crStatus
      athenaStatus
      isPendingConfirmation

      appointmentType {
        ...AppointmentTypeWithParent
      }
      client {
        id
        name
        firstName
        lastName
        clinic {
          id
          abbreviation
        }
        profile {
          dob
        }
        __typename @skip(if: true)
      }
      clinic {
        id
        name
        abbreviation
        __typename
      }
      provider {
        id
        name
        lastName
        firstName
        speciality {
          id
          abbreviation
        }
        clinic {
          id
          abbreviation
        }
        __typename
      }
      note {
        note
      }
      room {
        id
      }
      paymentMethod
      type
      isObservation
      leadEvent {
        id
        provider {
          id
          name
          lastName
          firstName
        }
      }
      observationsCount
      observations {
        id
        provider {
          ...Provider
        }
      }
      __typename @skip(if: true)
    }
    ${providerFragments.provider}
    ${addressFragments.addressData}
    ${appointmentTypesFragments.appointmentTypeWithParent}
  `,
  fullEventData: gql`
    fragment FullEventData on Event {
      id
      subject
      startDate
      duration
      endDate
      originalStartDate
      originalEndDate
      status
      type
      note {
        id
        note
      }
      master {
        id
      }
      crStatus
      athenaStatus
      isPendingConfirmation
      locationCategory
      locationType
      clinicPreference
      telehealthLink
      clinic {
        id
        name
        abbreviation
      }
      client {
        id
        clinic {
          id
          name
          abbreviation
        }
        name
        lastName
        firstName
        firstNameUsed
        profile {
          id
          dob
          legalSex
          gender
          phenoType
          phonePreference
          preferredPhone
        }
      }
      provider {
        ...Provider
      }
      appointmentType {
        id
        title
        headerColor
        backgroundColor
        eventType {
          id
          name
        }
        parent {
          id
          title
          headerColor
          backgroundColor
          eventType {
            id
            name
          }
        }
      }
      recurrencePattern {
        ...RecurrencePatternData
      }
      eventAudit {
        id
        action
        actionBy
        actionAt
        reason
        note
      }

      address {
        ...AddressData
      }
      room {
        ...RoomsData
      }
      paymentMethod
      syncAction {
        id
        retryCount
        failureReason
      }
      observations {
        id
        provider {
          ...Provider
        }
        clinic {
          id
        }
        startDate
        endDate
        locationType
        telehealthLink
        duration
      }
      isObservation
      observationsCount
      driveTimeSourceAddr
      driveTimeDesteAddr
      driveTimeMileage
      isFirstOrLastDriveToNonClinic
      reimbursableMileage
    }
    ${providerFragments.provider}
    ${addressFragments.addressData}
    ${recurrencePatternFragments.recurrencePattern}
    ${clinicFragments.roomsData}
  `,
  fullObserverEventData: gql`
    fragment FullObserverEventData on Event {
      id
      subject
      startDate
      duration
      endDate
      status
      telehealthLink
      locationType
      locationCategory
      address {
        ...AddressData
      }
      appointmentType {
        id
        title
        headerColor
        backgroundColor
        eventType {
          id
          name
        }
        parent {
          id
          title
          headerColor
          backgroundColor
          eventType {
            id
            name
          }
        }
      }
      provider {
        ...Provider
      }
      eventAudit {
        id
        action
        actionBy
        actionAt
        reason
        note
      }
      leadEvent {
        id
        locationCategory
        locationType
        telehealthLink
        provider {
          ...Provider
        }
        clinic {
          id
          name
          abbreviation
        }
        client {
          id
          clinic {
            id
            name
            abbreviation
          }
          name
          lastName
          firstName
          firstNameUsed
        }
        address {
          ...AddressData
        }
        room {
          ...RoomsData
        }
      }
    }
    ${providerFragments.provider}
    ${addressFragments.addressData}
    ${clinicFragments.roomsData}
  `,
  calendarCancelledEventData: gql`
    fragment CalendarCancelledEventData on CanceledEvent {
      id
      subject
      startDate
      endDate
      status
      locationType
      locationCategory
      recurringType
      recurringEvery
      recurringUntil
      crStatus
      athenaStatus
      isPendingConfirmation
      appointmentType {
        ...AppointmentTypeWithParent
      }
      client {
        id
        name
        firstName
        lastName
        name
        __typename
      }
      clinic {
        id
        name
        abbreviation
        __typename
      }
      provider {
        id
        name
        lastName
        firstName
        __typename
      }
      room {
        id
        name
        __typename
      }
      paymentMethod
      __typename
    }
    ${appointmentTypesFragments.appointmentTypeWithParent}
  `
};
