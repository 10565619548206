/* eslint-disable no-constant-condition */
import React, { Dispatch, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { Card } from 'semantic-ui-react';
import { isCheckedIndex } from 'utils/validators/smartSchedule';
import { CardWrapper } from './Style';
import RecurringResultHeader from './RecurringResultHeader';
import RecurringResultDescription from './RecurringResultDescription';
import RecurringSSRangeResultDescription from './SmartScheduleRangeResults/RecurringSSRangeResultDescription';
import { IAddress, ISmartResultCard } from 'model/v2';

interface IProps {
  clientTimezone: string;
  appointmentTypeTitle: string;
  result: ISmartResultCard;
  index: number;
  updateSelectedCards: (idx: number, isChecked: boolean) => void;
  setOpeningCards: Dispatch<React.SetStateAction<ISmartResultCard[]>>;
  clientAddresses: IAddress[];
}
const RecurringResultItemCard: React.FC<IProps> = ({
  clientTimezone,
  appointmentTypeTitle,
  result,
  index,
  updateSelectedCards,
  setOpeningCards,
  clientAddresses
}: IProps) => {
  const methods = useFormContext();
  const { watch } = methods;
  const isCheckedCard = watch(isCheckedIndex(index));
  const border = useMemo(
    () => (isCheckedCard ? '2px solid #6f42f5' : '2px solid #f4efef'),
    [isCheckedCard]
  );
  const rangeBasedResult = !!result.timeRanges;

  return (
    <CardWrapper>
      <div className={isCheckedCard ? 'checked' : ''}>
        <Card fluid style={{ border: border }}>
          <Card.Content
            style={{ border: 'none', borderBottom: '1px solid #f4efef' }}
            header={
              <RecurringResultHeader
                appointmentTitle={appointmentTypeTitle}
                result={result}
                index={index}
                isCheckedCard={isCheckedCard}
                updateSelectedCards={updateSelectedCards}
              />
            }
          />
          <Card.Content
            style={{ border: 'none' }}
            description={
              rangeBasedResult ? (
                <RecurringSSRangeResultDescription
                  clientTimezone={clientTimezone}
                  result={result}
                  isCheckedCard={isCheckedCard}
                  index={index}
                  setOpeningCards={setOpeningCards}
                  updateSelectedCards={updateSelectedCards}
                  clientAddresses={clientAddresses}
                />
              ) : (
                <RecurringResultDescription
                  clientTimezone={clientTimezone}
                  result={result}
                  isCheckedCard={isCheckedCard}
                  index={index}
                  setOpeningCards={setOpeningCards}
                  updateSelectedCards={updateSelectedCards}
                  clientAddresses={clientAddresses}
                />
              )
            }
          />
        </Card>
      </div>
    </CardWrapper>
  );
};
export default React.memo(RecurringResultItemCard);
