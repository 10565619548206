import React from 'react';
import { toWords } from 'number-to-words';
import { Button } from 'lib/ui';
import { STEPS } from '../utils';
import { IConflictMapped } from 'model/v2';

interface Props {
  eventsConflicts: IConflictMapped[][];
  setStep: (val: number) => void;
  backToForm: () => void;
}
const SmartConflictsFooter: React.FC<Props> = ({
  eventsConflicts,
  backToForm,
  setStep
}: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        borderTop: '1px solid #d8d8d8'
      }}
    >
      <h2>Warning</h2>
      {eventsConflicts.map((eventConflicts, index) => (
        <p>
          The selected series {index + 1} you’ve created has{' '}
          {toWords(eventConflicts.length)} ({eventConflicts.length})
          conflict(s):
        </p>
      ))}
      {/* <SliderItems events={conflicts} isSidebar={true} /> */}
      <Button
        className="secondary"
        type=""
        onClick={() => {
          setStep(STEPS.SUBMIT);
        }}
      >
        Keep on Schedule
      </Button>
      <Button className="secondary" type="" onClick={backToForm}>
        Back To Form
      </Button>
    </div>
  );
};
export default React.memo(SmartConflictsFooter);
