import { SubscriptionClient } from 'subscriptions-transport-ws';

const wsClient = new SubscriptionClient(
  process.env.REACT_APP_AWS_WEBSOCKET_URL!,
  {
    reconnect: true
  }
);

export default wsClient;
