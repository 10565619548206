import React, {
  Dispatch,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Radio } from 'semantic-ui-react';

import {
  CategoryIndex,
  addressIndex,
  endTimeNormalIndex,
  isCheckedIndex,
  isInVisibleIndex,
  startTimeNormalIndex
} from 'utils/validators/smartSchedule';

import { DescriptionWrapper } from './Style';
import CalendarBlank from 'assets/img/CalendarBlank.png';
import MapPin from 'assets/img/MapPin.png';
import ArrowClockWise from 'assets/img/ArrowsClockwise.png';
import SelectList from 'api/sharedComponents/reactHookFormComponents/semantic/SelectList';
import TelehealthInput from '../Client/TelehealthInput';
import moment from 'antd/node_modules/moment';
import {
  teleHealthIndex,
  startTimeIndex,
  endTimeIndex
} from 'utils/validators/smartSchedule';
import { IAddress, ISmartResultCard } from 'model/v2';
import {
  CLIENT_AVAILABLE_DAYS,
  LOCATION_CATEGORY_LIST
} from 'utils/constants/lists';
import { getTotalMin } from 'utils/format';
const momentTz = require('moment-timezone');

interface IProps {
  result: any;
  isCheckedCard: boolean;
  index: number;
  clientTimezone: string;
  setOpeningCards: Dispatch<React.SetStateAction<ISmartResultCard[]>>;
  updateSelectedCards: (idx: number, isChecked: boolean) => void;
  clientAddresses: IAddress[];
}
const RecurringResultDescription: React.FC<IProps> = ({
  result,
  index,
  clientTimezone,
  setOpeningCards,
  updateSelectedCards,
  isCheckedCard,
  clientAddresses
}: IProps) => {
  const methods = useFormContext();
  const { control, setValue, errors } = useFormContext();
  // let isAba = true;
  const clientAvailableDays: { [key: string]: string } = CLIENT_AVAILABLE_DAYS;

  const [slotTiming, setSlotTiming] = useState<any>();
  const [slots, setSlots] = useState<any>();
  const option1 = { timeZone: clientTimezone };
  const clinetTimezoneVal = new Date()
    .toLocaleTimeString('en-us', {
      timeZoneName: 'short',
      timeZone: clientTimezone
    })
    .split(' ')[2];
  useEffect(() => {
    if (result !== undefined && result?.timeSlots !== undefined) {
      const slots = result?.timeSlots?.map((time: any) => {
        const dateTimeStart = new Date(time?.timeSlot?.startTime!);
        const dateTimeEnd = new Date(time?.timeSlot?.endTime!);
        return {
          value: time?.timeSlot!,
          text:
            moment(dateTimeStart.toLocaleString('en-US', option1)).format(
              'MMMM D, YYYY h:mm a'
            ) +
            ' - ' +
            moment(dateTimeEnd.toLocaleString('en-US', option1)).format(
              'h:mm a'
            )
        };
      });
      setSlotTiming(slots[0]?.value);
      setSlots(slots);
    }
  }, [result?.timeSlots]);
  const providerTimezone = result.provider?.clinic?.timezone!;
  const providerTimezoneVal = new Date()
    .toLocaleTimeString('en-us', {
      timeZoneName: 'short',
      timeZone: providerTimezone
    })
    .split(' ')[2];
  const onTimeSlotChange = useCallback(
    data => {
      setValue(`time-dropdown`, data.value);
      setValue(startTimeIndex(index), getTotalMin(data.value.startTime));
      setValue(endTimeIndex(index), getTotalMin(data.value.endTime));
      setValue(startTimeNormalIndex(index), data.value.startTime);
      setValue(endTimeNormalIndex(index), data.value.endTime);
      setSlotTiming(data.value);
      setOpeningCards(prevOpeningCards => {
        // Create a new array with the updated value at the specified index
        const newOpeningCards = [...prevOpeningCards];
        newOpeningCards[index].startTime = data.value.startTime;
        newOpeningCards[index].endTime = data.value.endTime;
        return newOpeningCards;
      });
      // TODO: somehow this needs to trigger update in phantom Event
    },
    [setValue, setOpeningCards, setSlotTiming]
  );
  const categoriesOptions = useMemo(
    () =>
      LOCATION_CATEGORY_LIST.map(it => ({
        key: it.id,
        value: it.id,
        text: it.value
      })),
    []
  );
  const clientAddressesOptions = useMemo(() => {
    return clientAddresses?.map(it => ({
      text: `${it.addressLine1 || ''},${it.addressLine2 || ''},${it.city ||
        ''},${it.state || ''},${it.zipCode || ''}`,
      value: {
        id: it.id,
        addressLine1: it.addressLine1,
        addressLine2: it.addressLine2,
        city: it.city,
        state: it.state,
        zipCode: it.zipCode,
        name: it.name,
        country: it.country
      },
      key: it.id
    }));
  }, [clientAddresses]);
  return (
    <>
      <DescriptionWrapper>
        <Grid className="desc-cls">
          <Grid.Row className="pb-5" style={{ paddingBottom: '0px' }}>
            <Grid.Column width={5}>
              <div>
                <img src={CalendarBlank} alt="Calender" />
                <label className="day">
                  {clientAvailableDays[result?.dayOfWeek!]}s
                </label>
              </div>
            </Grid.Column>
            <Grid.Column width={11}>
              <div>
                <label>
                  Appointment Type: {result?.appointmentType?.title!}
                </label>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className="pb-5 cursor"
            onClick={() => {
              setValue(isCheckedIndex(index), !isCheckedCard);
              updateSelectedCards(index, !isCheckedCard);
            }}
          ></Grid.Row>
          <Grid.Row
            className="dopdown-cls pb-5 cursor"
            style={{ paddingBottom: '0px' }}
            onClick={() => {
              setValue(isCheckedIndex(index), !isCheckedCard);
              updateSelectedCards(index, !isCheckedCard);
            }}
          >
            <Grid.Column width="1">
              <img
                src={ArrowClockWise}
                className="clock-wise"
                alt="Arrow Clock wise"
              />
            </Grid.Column>
            <Grid.Column width="11">
              <Controller
                name={`time-dropdown`}
                control={control}
                defaultValue=""
                render={props => (
                  <SelectList
                    field={props}
                    errors={errors}
                    options={slots}
                    value={slotTiming}
                    disabled={!isCheckedCard}
                    onChange={(_view: any, data: any) => {
                      onTimeSlotChange(data);
                    }}
                  />
                )}
              />
            </Grid.Column>
            <Grid.Column width="4">
              <label className="cursor">{clinetTimezoneVal}</label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className="dopdown-cls pb-5 cursor"
            style={{ paddingBottom: '0px', marginTop: '10px' }}
          >
            <Grid.Column
              width="1"
              onClick={() => {
                setValue(isCheckedIndex(index), !isCheckedCard);
                updateSelectedCards(index, !isCheckedCard);
              }}
            >
              <img src={MapPin} className="map-pin" alt="Map Pin" />
            </Grid.Column>
            <Grid.Column
              width="3"
              onClick={() => {
                setValue(isCheckedIndex(index), !isCheckedCard);
                updateSelectedCards(index, !isCheckedCard);
              }}
            >
              <label className="location-cls">
                {result?.allowedLocations[0]!}
              </label>
            </Grid.Column>
            {result?.isInClinic! && (
              <Grid.Column
                width="10"
                onClick={() => {
                  setValue(isCheckedIndex(index), !isCheckedCard);
                  updateSelectedCards(index, !isCheckedCard);
                }}
              >
                <div style={{ paddingLeft: '15px' }}>
                  <label>
                    <b>Clinic: </b> {result?.clinic?.name!}
                  </label>
                </div>
              </Grid.Column>
            )}
            {result?.isTelehealth! && (
              <Grid.Column width="8">
                <div
                  style={{
                    paddingLeft: '15px',
                    position: 'relative',
                    bottom: '8px'
                  }}
                >
                  <TelehealthInput
                    type="smart"
                    index={teleHealthIndex(index)}
                    telehealthLinks={result.provider?.telehealthLinks}
                    isCheckedCard={isCheckedCard}
                  />
                </div>
              </Grid.Column>
            )}
            {result?.isOffsite! && (
              <>
                <Grid.Column width="6">
                  <Controller
                    name={CategoryIndex(index)}
                    control={control}
                    render={props => (
                      <SelectList
                        field={props}
                        style={{ minWidth: 'unset !important' }}
                        errors={errors}
                        options={categoriesOptions!}
                        disabled={!isCheckedCard}
                        placeholder="Select Category"
                        onChange={(_view: any, data: any) => {
                          setValue(CategoryIndex(index), data.value);
                          methods.trigger(CategoryIndex(index));
                        }}
                      />
                    )}
                  />
                </Grid.Column>
              </>
            )}
            {result?.isOffsite! && (
              <>
                <Grid.Column width="6">
                  <Controller
                    name={addressIndex(index)}
                    control={control}
                    render={props => (
                      <SelectList
                        field={props}
                        style={{ minWidth: 'unset !important' }}
                        errors={errors}
                        options={clientAddressesOptions!}
                        disabled={!isCheckedCard}
                        placeholder="Select Address"
                        onChange={(_view: any, data: any) => {
                          setValue(addressIndex(index), data.value);
                          methods.trigger(addressIndex(index));
                        }}
                      />
                    )}
                  />
                </Grid.Column>
              </>
            )}
          </Grid.Row>
          <Grid.Row
            className="pb-5 cursor"
            onClick={() => {
              setValue(isCheckedIndex(index), !isCheckedCard);
              updateSelectedCards(index, !isCheckedCard);
            }}
          >
            <Grid.Column>
              <div style={{ paddingTop: '5px' }}>
                <div className="cursor">
                  <label style={{ fontWeight: 'bold', paddingRight: '5px' }}>
                    Provider's Appointment Time:
                  </label>
                  <label className="cursor" style={{ paddingRight: '5px' }}>
                    {momentTz
                      .tz(slotTiming?.startTime!, providerTimezone)
                      .format('hh:mm A')}{' '}
                    -{' '}
                    {momentTz
                      .tz(slotTiming?.endTime!, providerTimezone)
                      .format('hh:mm A')}
                  </label>
                  <label className="cursor">{providerTimezoneVal}</label>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column className="ml-25">
              <div className="visibleContainer">
                <label className="visibleText">Save as Invisible</label>
                <Controller
                  name={isInVisibleIndex(index)}
                  control={control}
                  render={props => (
                    <Radio
                      toggle
                      disabled={!isCheckedCard}
                      onChange={(_, { checked }) => {
                        setValue(isInVisibleIndex(index), checked);
                      }}
                      checked={props.value}
                    />
                  )}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </DescriptionWrapper>
    </>
  );
};
export default React.memo(RecurringResultDescription);
