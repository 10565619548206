import React, { Dispatch, useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Grid, Radio } from 'semantic-ui-react';
import {
  Grid as MUIGrid,
  Select,
  TextField,
  MenuItem,
  FormHelperText
} from '@material-ui/core';

import {
  CategoryIndex,
  addressIndex,
  endTimeNormalIndex,
  isCheckedIndex,
  isInVisibleIndex,
  startTimeNormalIndex
} from 'utils/validators/smartSchedule';

import { DescriptionWrapper } from '../Style';
import CalendarBlank from 'assets/img/CalendarBlank.png';
import MapPin from 'assets/img/MapPin.png';
import ArrowClockWise from 'assets/img/ArrowsClockwise.png';
import SelectList from 'api/sharedComponents/reactHookFormComponents/semantic/SelectList';
import TelehealthInput from '../../Client/TelehealthInput';
import moment from 'antd/node_modules/moment';
import {
  teleHealthIndex,
  startTimeIndex,
  endTimeIndex,
  rangeBasedMultiFieldErrorMessage
} from 'utils/validators/smartSchedule';
import { IAddress, ISmartResultCard, TimeSlots } from 'model/v2';
import {
  CLIENT_AVAILABLE_DAYS,
  LOCATION_CATEGORY_LIST
} from 'utils/constants/lists';
import { getTotalMin } from 'utils/format';
const momentTz = require('moment-timezone');

type DropdownOption = {
  value: {
    startTime: string;
    endTime: string;
    adjacentType: number;
  };
  text: string;
};

const categoriesOptions = LOCATION_CATEGORY_LIST.map(it => ({
  key: it.id,
  value: it.id,
  text: it.value
}));

const is15MinIncrement = (time: string) => {
  const momentTime = moment(time);
  const [, startMM] = momentTime.format('HH:mm').split(':');
  return parseInt(startMM) % 15 === 0;
};

const validateBoth = (
  HHmmStart: string,
  HHmmEnd: string,
  selectedOption: DropdownOption
) => {
  const momentHHmmStartTime = moment(HHmmStart);
  const momentSelectedOptionStart = moment(selectedOption.value.startTime);

  const momentHHmmEndTime = moment(HHmmEnd);
  const momentSelectedOptionEnd = moment(selectedOption.value.endTime);

  const durationTooShort =
    momentHHmmEndTime.diff(momentHHmmStartTime, 'minutes') < 60 * 2;
  const durationTooLong =
    momentHHmmEndTime.diff(momentHHmmStartTime, 'minutes') > 60 * 5;

  const startTimeOutOfRange = momentHHmmStartTime < momentSelectedOptionStart;
  const endTimeOutOfRange = momentHHmmEndTime > momentSelectedOptionEnd;
  const bothIn15MinIncrements =
    is15MinIncrement(HHmmStart) && is15MinIncrement(HHmmEnd);

  return {
    durationTooLong,
    durationTooShort,
    bothIn15MinIncrements,
    startTimeOutOfRange: startTimeOutOfRange
      ? {
          rangeStartTime: momentSelectedOptionStart.format('hh:mm A')
        }
      : undefined,
    endTimeOutOfRange: endTimeOutOfRange
      ? {
          rangeEndTime: momentSelectedOptionEnd.format('hh:mm A')
        }
      : undefined
  };
};

interface IProps {
  result: ISmartResultCard;
  isCheckedCard: boolean;
  index: number;
  clientTimezone: string;
  setOpeningCards: Dispatch<React.SetStateAction<ISmartResultCard[]>>;
  updateSelectedCards: (idx: number, isChecked: boolean) => void;
  clientAddresses: IAddress[];
}

const RecurringResultDescription: React.FC<IProps> = ({
  result,
  index,
  clientTimezone,
  updateSelectedCards,
  isCheckedCard,
  clientAddresses
}: IProps) => {
  const methods = useFormContext();
  const {
    control,
    setValue,
    errors,
    setError,
    clearErrors,
    formState: { submitCount }
  } = useFormContext();
  const clientAvailableDays: { [key: string]: string } = CLIENT_AVAILABLE_DAYS;

  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[] | ''>(
    ''
  );
  const [selectedOption, setSelectedOption] = useState<DropdownOption | ''>('');

  const [startTimeHHmm, setStartTimeHHmm] = useState('');
  const [endTimeHHmm, setEndTimeHHmm] = useState('');

  const resultTimeslots = result?.timeSlots;
  // useEffect that translates incoming result timeslots into dropdown options and stores to local state
  useEffect(() => {
    if (result !== undefined && resultTimeslots !== undefined) {
      const localStringOptsObj = { timeZone: clientTimezone };
      const options = resultTimeslots!.map((time: TimeSlots) => {
        const dateTimeStart = new Date(time?.timeSlot?.startTime!);
        const dateTimeEnd = new Date(time?.timeSlot?.endTime!);
        return {
          value: time?.timeSlot!,
          text:
            moment(
              dateTimeStart.toLocaleString('en-US', localStringOptsObj)
            ).format('MMMM D, YYYY h:mm a') +
            ' - ' +
            moment(
              dateTimeEnd.toLocaleString('en-US', localStringOptsObj)
            ).format('h:mm a')
        };
      });
      setSelectedOption(options[0]);
      setDropdownOptions(options);
    }
  }, [result, resultTimeslots, clientTimezone]);

  // useEffect that updates the RHF values when HHmm changes happen, but dropdown option hasn't changed
  useEffect(() => {
    if (selectedOption) {
      const [startHH, startMm] = startTimeHHmm.split(':');
      const [endHH, endMm] = endTimeHHmm.split(':');
      const HHmmAdjustedStart = moment(selectedOption.value.startTime)
        .set('hours', parseInt(startHH))
        .set('minutes', parseInt(startMm))
        .toISOString();
      const HHmmAdjustedEnd = moment(selectedOption.value.startTime)
        .set('hours', parseInt(endHH))
        .set('minutes', parseInt(endMm))
        .toISOString();

      // set minutes from midnight for start/endTime
      setValue(startTimeIndex(index), getTotalMin(HHmmAdjustedStart));
      setValue(endTimeIndex(index), getTotalMin(HHmmAdjustedEnd));

      // set actual values for start/endTime
      setValue(startTimeNormalIndex(index), HHmmAdjustedStart);
      setValue(endTimeNormalIndex(index), HHmmAdjustedEnd);

      const {
        durationTooShort,
        durationTooLong,
        bothIn15MinIncrements,
        startTimeOutOfRange,
        endTimeOutOfRange
      } = validateBoth(HHmmAdjustedStart, HHmmAdjustedEnd, selectedOption); // could look at pulling validation into own effect hook

      let errorMsg = '';

      if (startTimeOutOfRange) {
        errorMsg =
          errorMsg +
          `Start time must be ${startTimeOutOfRange.rangeStartTime} or later. `;
      }
      if (endTimeOutOfRange) {
        errorMsg =
          errorMsg +
          `End time must be ${endTimeOutOfRange.rangeEndTime} or before. `;
      }
      if (durationTooShort || durationTooLong) {
        errorMsg = errorMsg + 'Ensure time between 2 and 5 hours. ';
      }
      if (!bothIn15MinIncrements) {
        errorMsg = errorMsg + 'Ensure 15 minute increments. ';
      }

      // setTimeSelectionError(errorMsg);
      if (errorMsg) {
        setError(rangeBasedMultiFieldErrorMessage(index), {
          type: 'validate',
          message: errorMsg
        });
      } else {
        clearErrors(rangeBasedMultiFieldErrorMessage(index));
      }
    }
  }, [
    startTimeHHmm,
    endTimeHHmm,
    index,
    selectedOption,
    setValue,
    setError,
    clearErrors,
    submitCount // because RHF resets unregistered field errors onSubmit, re-trig effect to revalidate unregistered fields
  ]);

  // useEffect populates the timeslot selectors when selectedOption changes (dropdown option selection, first render, filter changes)
  useEffect(() => {
    if (selectedOption) {
      const { startTime, endTime } = selectedOption.value;
      setStartTimeHHmm(moment(startTime).format('HH:mm'));
      setEndTimeHHmm(moment(endTime).format('HH:mm'));

      setValue(
        startTimeIndex(index),
        getTotalMin(selectedOption.value.startTime)
      );
      setValue(endTimeIndex(index), getTotalMin(selectedOption.value.endTime));
      setValue(startTimeNormalIndex(index), selectedOption.value.startTime);
      setValue(endTimeNormalIndex(index), selectedOption.value.endTime);
    }
  }, [selectedOption, index, setValue]);

  const clientAddressesOptions = useMemo(() => {
    return clientAddresses?.map(it => ({
      text: `${it.addressLine1 || ''},${it.addressLine2 || ''},${it.city ||
        ''},${it.state || ''},${it.zipCode || ''}`,
      value: {
        id: it.id,
        addressLine1: it.addressLine1,
        addressLine2: it.addressLine2,
        city: it.city,
        state: it.state,
        zipCode: it.zipCode,
        name: it.name,
        country: it.country
      },
      key: it.id
    }));
  }, [clientAddresses]);

  const providerTimezone = result.provider?.clinic?.timezone!;
  const providerTimezoneVal = new Date()
    .toLocaleTimeString('en-us', {
      timeZoneName: 'short',
      timeZone: providerTimezone
    })
    .split(' ')[2];

  const clinetTimezoneVal = new Date()
    .toLocaleTimeString('en-us', {
      timeZoneName: 'short',
      timeZone: clientTimezone
    })
    .split(' ')[2];

  return (
    <>
      <DescriptionWrapper>
        <Grid className="desc-cls">
          <Grid.Row className="pb-5" style={{ paddingBottom: '0px' }}>
            <Grid.Column width={5}>
              <div>
                <img src={CalendarBlank} alt="Calender" />
                <label className="day">
                  {clientAvailableDays[result?.dayOfWeek!]}s
                </label>
              </div>
            </Grid.Column>
            <Grid.Column width={11}>
              <div>
                <label>
                  Appointment Type: {result?.appointmentType?.title!}
                </label>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className="pb-5 cursor"
            onClick={() => {
              setValue(isCheckedIndex(index), !isCheckedCard);
              updateSelectedCards(index, !isCheckedCard);
            }}
          ></Grid.Row>
          <Grid.Row
            className="dopdown-cls pb-5 cursor"
            style={{ paddingBottom: '0px' }}
            onClick={() => {
              setValue(isCheckedIndex(index), !isCheckedCard);
              updateSelectedCards(index, !isCheckedCard);
            }}
          >
            <Grid.Column width="1">
              <img
                src={ArrowClockWise}
                className="clock-wise icon-offset"
                alt="Arrow Clock wise"
              />
            </Grid.Column>
            <Grid.Column width="14">
              <MUIGrid container>
                <MUIGrid item xs={12}>
                  <Select
                    value={selectedOption}
                    defaultValue={selectedOption}
                    onClick={e => e.stopPropagation()}
                    style={{ width: '100%' }}
                    // @ts-ignore
                    onChange={(
                      e: React.ChangeEvent<{
                        name?: string | undefined;
                        value: DropdownOption;
                      }>
                    ) => setSelectedOption(e.target.value)}
                    //@ts-ignore
                    renderValue={(selected: DropdownOption) => {
                      return (
                        <MUIGrid
                          item
                          xs={12}
                          container
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <MUIGrid item xs={1}>
                            {selectedOption
                              ? moment(
                                  new Date(
                                    selectedOption.value.startTime
                                  ).toLocaleString('en-US', {
                                    timeZone: clientTimezone
                                  })
                                ).format('MMM D')
                              : ''}
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              type="time"
                              value={startTimeHHmm}
                              onMouseDown={e => e.stopPropagation()}
                              onKeyDown={e => e.stopPropagation()}
                              onChange={e => {
                                setStartTimeHHmm(e.target.value);
                              }}
                              inputProps={{
                                step: 900 // 5 min
                              }}
                            />
                          </MUIGrid>
                          <MUIGrid item xs={4}>
                            <TextField
                              type="time"
                              value={endTimeHHmm}
                              onMouseDown={e => e.stopPropagation()}
                              onKeyDown={e => e.stopPropagation()}
                              onChange={e => {
                                setEndTimeHHmm(e.target.value);
                              }}
                              inputProps={{
                                step: 900 // 5 min,
                              }}
                            />
                          </MUIGrid>
                        </MUIGrid>
                      );
                    }}
                  >
                    {dropdownOptions &&
                      dropdownOptions?.map(dropdownOption => {
                        return (
                          //@ts-ignore
                          <MenuItem value={dropdownOption}>
                            {dropdownOption.text}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </MUIGrid>
                <MUIGrid item xs={12}>
                  <FormHelperText
                    error={
                      !!errors?.['results']?.[index]?.['multiFieldErrorMessage']
                    }
                  >
                    {
                      errors?.['results']?.[index]?.['multiFieldErrorMessage']
                        ?.message
                    }
                  </FormHelperText>
                </MUIGrid>
              </MUIGrid>
            </Grid.Column>
            <Grid.Column width="1">
              <label className="cursor client-tz-offset">
                {clinetTimezoneVal}
              </label>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className="dopdown-cls pb-5 cursor"
            style={{ paddingBottom: '0px', marginTop: '10px' }}
          >
            <Grid.Column
              width="1"
              onClick={() => {
                setValue(isCheckedIndex(index), !isCheckedCard);
                updateSelectedCards(index, !isCheckedCard);
              }}
            >
              <img src={MapPin} className="map-pin" alt="Map Pin" />
            </Grid.Column>
            <Grid.Column
              width="3"
              onClick={() => {
                setValue(isCheckedIndex(index), !isCheckedCard);
                updateSelectedCards(index, !isCheckedCard);
              }}
            >
              <label className="location-cls">
                {result?.allowedLocations[0]!}
              </label>
            </Grid.Column>
            {result?.isInClinic! && (
              <Grid.Column
                width="10"
                onClick={() => {
                  setValue(isCheckedIndex(index), !isCheckedCard);
                  updateSelectedCards(index, !isCheckedCard);
                }}
              >
                <div style={{ paddingLeft: '15px' }}>
                  <label>
                    <b>Clinic: </b> {result?.clinic?.name!}
                  </label>
                </div>
              </Grid.Column>
            )}
            {result?.isTelehealth! && (
              <Grid.Column width="8">
                <div
                  style={{
                    paddingLeft: '15px',
                    position: 'relative',
                    bottom: '8px'
                  }}
                >
                  <TelehealthInput
                    type="smart"
                    index={teleHealthIndex(index)}
                    telehealthLinks={result.provider?.telehealthLinks}
                    isCheckedCard={isCheckedCard}
                  />
                </div>
              </Grid.Column>
            )}
            {result?.isOffsite! && (
              <>
                <Grid.Column width="6">
                  <Controller
                    name={CategoryIndex(index)}
                    control={control}
                    render={props => (
                      <SelectList
                        field={props}
                        style={{ minWidth: 'unset !important' }}
                        errors={errors}
                        options={categoriesOptions!}
                        disabled={!isCheckedCard}
                        placeholder="Select Category"
                        onChange={(_view: any, data: any) => {
                          setValue(CategoryIndex(index), data.value);
                          methods.trigger(CategoryIndex(index));
                        }}
                      />
                    )}
                  />
                </Grid.Column>
              </>
            )}
            {result?.isOffsite! && (
              <>
                <Grid.Column width="6">
                  <Controller
                    name={addressIndex(index)}
                    control={control}
                    render={props => (
                      <SelectList
                        field={props}
                        style={{ minWidth: 'unset !important' }}
                        errors={errors}
                        options={clientAddressesOptions!}
                        disabled={!isCheckedCard}
                        placeholder="Select Address"
                        onChange={(_view: any, data: any) => {
                          setValue(addressIndex(index), data.value);
                          methods.trigger(addressIndex(index));
                        }}
                      />
                    )}
                  />
                </Grid.Column>
              </>
            )}
          </Grid.Row>
          <Grid.Row
            className="pb-5 cursor"
            onClick={() => {
              setValue(isCheckedIndex(index), !isCheckedCard);
              updateSelectedCards(index, !isCheckedCard);
            }}
          >
            <Grid.Column>
              <div style={{ paddingTop: '5px' }}>
                <div className="cursor">
                  <label style={{ fontWeight: 'bold', paddingRight: '5px' }}>
                    Provider's Appointment Time:
                  </label>
                  <label className="cursor" style={{ paddingRight: '5px' }}>
                    {momentTz
                      .tz(
                        selectedOption
                          ? selectedOption.value?.startTime!
                          : selectedOption,
                        providerTimezone
                      )
                      .format('hh:mm A')}{' '}
                    -{' '}
                    {momentTz
                      .tz(
                        selectedOption
                          ? selectedOption.value?.endTime!
                          : selectedOption,
                        providerTimezone
                      )
                      .format('hh:mm A')}
                  </label>
                  <label className="cursor">{providerTimezoneVal}</label>
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2">
            <Grid.Column className="ml-25">
              <div className="visibleContainer">
                <label className="visibleText">Save as Invisible</label>
                <Controller
                  name={isInVisibleIndex(index)}
                  control={control}
                  render={props => (
                    <Radio
                      toggle
                      disabled={!isCheckedCard}
                      onChange={(_, { checked }) => {
                        setValue(isInVisibleIndex(index), checked);
                      }}
                      checked={props.value}
                    />
                  )}
                />
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </DescriptionWrapper>
    </>
  );
};
export default React.memo(RecurringResultDescription);
