import styled from 'styled-components';
import * as colors from 'utils/constants/theme';

export const ResultListWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100% - 20%);
  .filter-cls {
    display: flex;
    flex-direction: column;
  }
  .flex-grow {
    flex-grow: 1;
  }
  .error-msg {
    color: #e82424;
    padding-left: 20px;
    padding-right: 20px;
  }
  .footer-ss-cls {
    position: fixed;
    z-index: 103;
    bottom: 1%;
    width: 99%;
    background-color: white;
  }
`;
export const CardWrapper = styled.div`
  padding-left: 22px;
  padding-right: 22px;
  padding-bottom: 15px;

  .ui.card > :first-child {
    padding: 0px !important;
  }
  .checked {
    .ui.card > .content,
    .ui.cards > .card > .content {
      border: 0.5px solid #6f42f5;
      box-sizing: border-box;
      border-radius: 5px;
      border-shadow: none;
    }
  }
`;
export const CardWrapperDT = styled.div`
  border: 10px solid white;
  .ui.card > :first-child {
    padding: 0px !important;
  }
  .content {
    padding-bottom: 3px !important;
    padding-top: 5px !important;
  }

  .ui.card > .content,
  .ui.cards > .card > .content {
    box-sizing: border-box;
    border-radius: 5px;
    border-shadow: none;
  }
  .ui.card {
    border-radius: 10px;
    background-color: transparent;
  }
`;
export const HeaderWrapper = styled.div`
  font-family: Lato;
  font-style: normal;
  color: #ffffff;
  header {
    padding: 1rem;
    padding-right: 0;
    padding-left: 0.5rem;
    padding-bottom: 1rem;
    margin: 0 auto;
    max-width: 1500px;
  }
  nav {
    max-height: 47px;
  }
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  nav ul {
    display: flex;
    flex-flow: row;
    gap: 0.5%;
  }

  nav li:first-child {
    flex-basis: 5%;
  }
  nav li:second-child {
    min-width: 110px;
  }
  nav li:third-child {
    flex-basis: 1%;
  }
  nav li:fourth-child {
    flex-basis: 4%;
  }
  nav li:fifth-child {
    flex-basis: 14%;
  }
  nav li:last-child {
    margin-left: auto;
  }
  .appointment {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
  }

  .provider {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    display: block;
    letter-spacing: -0.00133333px;
    color: #ffffff;
  }
  .userIcon {
    margin-left: 8px;
  }
  .rectangle {
    position: relative;
    text-align: center;
    align-item: end;
    color: white;
  }
  .centered {
    position: absolute;
    top: 34%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(111, 66, 245, 1);
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    min-width: 50px;
  }
  .series {
    font-weight: bold;
    font-size: 11px;
  }
  .child {
    display: inline-flex;
  }
  .check-box {
    position: absolute;
    top: 16px;
    left: 10px;
    max-width: 29px;
    min-width: 29px;
    max-height: 15px;
  }
  .dot {
    max-width: 9px;
    position: absolute;
    top: 11px;
    left: 10px;
  }
  .user-icon {
    position: absolute;
    top: 27%;
    left: 10px;
  }
  .ui.checkbox label:before,
  .ui.checkbox label:after,
  .ui.checkbox input:checked:focus ~ label:before,
  .ui.checkbox input:checked:focus ~ label:after,
  .ui.checkbox label:hover::before,
  .ui.checkbox label:hover::after,
  .ui.checkbox input:checked ~ label:before,
  .ui.checkbox input:checked ~ label:after {
    background: transparent;
    color: #ffffff;
    border-color: #ffffff;
  }
  .provider-cls {
    color: rgb(33, 48, 58) !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
  }
  .adjacent-cls p {
    color: rgb(33, 48, 58) !important;
    font-size: 12px;
    margin-bottom: 0px !important;
  }
  .adjacent-cls {
    position: relative;
    bottom: 2px;
    margin-right: 10px;
  }
  .adjacent-p {
    float: right;
  }
`;

export const SmartCardFooterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 7px 0px;
`;

export const HeaderWrapperDT = styled.div`
  font-family: Lato;
  font-style: normal;
  color: #ffffff;
  height: 47px;
  .flex-r-container {
    width: 100%;
    display: flex;
    height: 46px;
    margin: auto;
    padding-left: 10px;
    h4 {
      font-size: 16px;
      color: rgba(33, 48, 58, 1);
    }
    div {
      align-items: center
      display: flex;
      height: 100%;
    }
    .icon-container {
      width: 30px;
      height: 100%;
      justify-content: center !important;
      .fa-user, .fa-copy {
        width: 18px;
        height: 18px;
        color: #3e5766; 
      }
    }
    .max-w-container {
      width: fit-content;
      max-width: 260px;
      text-overflow: ellipsis;
      padding-right: 4px;
    }
    .clinic-abbr {
      width: fit-content;
      max-width: 80px;
      display: flex;
      height: 100%;
    }
  }
  header {
    padding: 0.5rem;
    padding-right: 0;
    padding-left: 0.5rem;
    padding-bottom: 1.5rem;
    max-height: 47px;
    margin: 0 auto;
    max-width: 1500px;
  }
  nav {
    max-height: 47px;
  }
  nav ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  nav ul {
    display: flex;
    flex-flow: column;
    gap: 0.5%;
  }

  nav li:first-child {
    flex-basis: 5%;
  }
  nav li:second-child {
    min-width: 110px;
  }
  nav li:third-child {
    flex-basis: 1%;
  }
  nav li:fourth-child {
    flex-basis: 4%;
  }
  nav li:fifth-child {
    flex-basis: 14%;
  }
  nav li:last-child {
    margin-left: auto;
  }
  .appointment {
    font-family: Lato;
    font-style: normal;
    font-weight: 900;
    font-size: 13px;
    line-height: 16px;
    color: black;
    margin-left: 18px;
  }

  .provider {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    display: inline;
    letter-spacing: -0.00133333px;
    color: black;
  }
  .userIcon {
    margin-left: 8px;
  }
  .rectangle {
    position: relative;
    text-align: center;
    align-item: end;
    color: white;
  }
  .centered {
    position: absolute;
    top: 34%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(111, 66, 245, 1);
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    min-width: 50px;
  }
  .series {
    font-weight: bold;
    font-size: 11px;
  }
  .child {
    display: inline-flex;
  }
  .check-box {
    position: absolute;
    top: 16px;
    left: 10px;
    max-width: 29px;
    min-width: 29px;
    max-height: 15px;
  }
  .dot {
    max-width: 9px;
    position: absolute;
    top: 11px;
    left: 10px;
  }
  .user-icon {
    position: absolute;
    top: 27%;
    left: 10px;
  }
  .ui.checkbox label:before,
  .ui.checkbox label:after,
  .ui.checkbox input:checked:focus ~ label:before,
  .ui.checkbox input:checked:focus ~ label:after,
  .ui.checkbox label:hover::before,
  .ui.checkbox label:hover::after,
  .ui.checkbox input:checked ~ label:before,
  .ui.checkbox input:checked ~ label:after {
    background: transparent;
    color: #ffffff;
    border-color: #ffffff;
  }
`;

export const DescriptionWrapper = styled.div`
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: rgba(111, 66, 245, 0.3) !important;
  }

  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: rgba(111, 66, 245, 0.3) !important;
  }
  .ui.toggle.checkbox .box:after,
  .ui.toggle.checkbox label:after {
    background-color: ${colors.PURPLE};
  }
  .row {
    display: flex;
    flex: row;
  }
  .visibleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
  }
  .visibleText {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: #3e5766;
  }
  .ui.dropdown .menu > .item {
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
  }
  .column-margin {
    margin-right: 20px;
    min-width: 168px;
    max-width: fit-content;
    max-height: 40px;
  }
  .location-style {
    min-width: 123px;
    max-width: 160px;
  }
  .pd-l {
    padding-left: 27px;
  }
  .ui.grid > .pt-0 {
    padding-top: 0px;
  }
  .ui.grid > .pb-5 {
    padding-bottom: 5px;
  }
  .pb-5 {
    padding-bottom: 5px;
  }
  .ui.grid > .row > .ml-25 {
    margin-left: 25%;
  }
  .desc-cls label {
    font-size: 12px;
  }
  .desc-cls .pb-5 {
    padding-top: 0px !important;
  }
  .desc-cls .day {
    color: #6f4bf1;
    font-weight: 600;
    padding-left: 2px;
  }
  .client-appt-cls {
    position: absolute;
    bottom: 0px;
    font-weight: 600;
  }
  .client-appt-cls label {
    padding-left: 2px;
  }
  .dopdown-cls img {
    padding-right: 2px;
    position: relative;
    bottom: 15px;
  }
  .provider-appt {
    padding-left: 15px;
  }
  .provider-appt-div {
    font-weight: 600;
  }
  .desc-cls .top-wrap {
    display: none;
  }
  .desc-cls .smart-input .ant-col > div {
    min-height: 0px !important;
  }
  .desc-cls .smart-input .drop-icon {
    bottom: 25px !important;
    position: relative;
    left: 130px !important;
    color: #6f4bf1;
  }
  .desc-cls .smart-input {
    height: 30px;
  }
  .desc-cls .ui.selection.dropdown {
    font-size: 10px;
    margin-left: 0px;
  }
  .desc-cls .clock-wise {
    position: relative;
    bottom: 0px;
  }
  .desc-cls .cursor {
    cursor: pointer;
  }
  .desc-cls .smart-input .dropdown-wrapper {
    margin-top: -20px;
  }

  .ui.selection.dropdown {
    min-width: unset !important;
    width: 90%;
  }
  .ui.dropdown > .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .desc-cls .map-pin {
    position: relative;
    bottom: -2px;
  }
  .location-cls {
    text-transform: capitalize;
  }
  .desc-cls .error {
    font-size: 12px;
  }

  .icon-offset {
    top: 10px;
  }

  .client-tz-offset {
    top: 11px;
    position: relative;
  }
`;
export const DescriptionWrapperDT = styled.div`
  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: rgba(111, 66, 245, 0.3) !important;
  }
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: rgba(111, 66, 245, 0.3) !important;
  }
  .ui.toggle.checkbox .box:after,
  .ui.toggle.checkbox label:after {
    background-color: ${colors.PURPLE};
  }
  .row {
    display: flex;
    flex: row;
    padding-bottom: 0px;
  }
  .visibleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
  }
  .visibleText {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: #3e5766;
  }
  .ui.dropdown .menu > .item {
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
  }
  .column-margin {
    margin-right: 20px;
    min-width: 168px;
    max-width: fit-content;
    max-height: 40px;
  }
  .location-style {
    min-width: 123px;
    max-width: 160px;
  }
  .ui.card > .content,
  .ui.cards > .card > .content {
    border-top: none;
  }
  .d-none {
    display: none !important;
  }
  .outer-shell {
    width: 100% !important;
  }
  .w-50-inline {
    height: auto;
    width: 50% !important;
  }
  .grid .row {
    padding: 0 !important;
  }
  .flx-1-1-grow {
    display: flex;
    height: auto;
    max-height: 75px;
  }
  .flx-fixed-w {
    width: 20px;
    height: 25px;
  }
  .color-custom {
    color: rgba(65, 87, 102, 1) !important;
  }
  .flx-h-v-c {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .flx-v-c {
    display: flex;
    align-items: center;
  }
  .flx-grow-1 {
    flex-grow: 1;
  }
  .icon-small {
    color: rgba(65, 87, 102, 1) !important;
    font-weight: 200 !important;
  }
  .f-size-12 {
    font-size: 12px;
  }
  .pad-8-px {
    padding-left: 8px !important;
  }
  .f-w-500 {
    font-weight: 500 !important;
  }
  .w-max {
    width: 100%;
  }
  .b-g-test {
    background-color: grey;
    height: 100px;
  }
  .time-zone-text {
    font-size: 10px;
    color: rgba(65, 87, 102, 1);
    font-weight: 600;
    display: block !important;
    line-height: 13px;
  }
  .d-flx {
    display: flex;
  }
  .flx-dr-c {
    flex-direction: column;
  }
  .m-top-5 {
    margin-top: 5px;
  }
  .m-top-15 {
    margin-top: 15px;
  }
  .p-left-15 {
    padding-left: 15px;
  }
  .p-left-23 {
    padding-left: 23px;
  }
  .span-m-3px {
    margin-left: 3px;
  }
  .appointment-info {
    margin-bottom: 5px;
  }
  .p-left-10 {
    padding-left: 10px !important;
  }
  .invisible-toggle {
    width: 100%;
    display: flex !important;
    justify-content: center;
  }
  .toggle-wrap {
    width: fit-content !important;
    display: flex;
  }
  .toggle-flex-item {
    display: flex !important;
    padding: 0px 2px;
    width: fit-content !important;
    height: 30px;
    align-items: center;
  }
  .v-align-c {
    display: flex;
    align-items: center;
  }
  .overf-ell {
    text-overflow: ellipses !important;
  }
`;

export const DateWrapper = styled.div`
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 19px;
  color: #21303a;
  background: rgba(201, 207, 211, 0.5);
  border-radius: 3px;
  margin-bottom: 8px;
  .circle {
    border-radius: 5px;
    width: 12px;
    height: 12px;
    color: #ffffff;
    text-align: center;
    font: 8px Lato, normal;
    font-weight: bold;
    margin-right: 8px;
    margin-left: 5px;
  }
`;
export const DateWrapperDT = styled.div`
  height: 24px;
  display: flex;
  justify-content: left;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  color: #21303a;
  background: transparent;
  margin-bottom: 8px;
  .circle {
    border-radius: 5px;
    width: 12px;
    height: 12px;
    color: #ffffff;
    text-align: center;
    font: 8px Lato, normal;
    font-weight: bold;
    margin-right: 8px;
    margin-left: 5px;
  }
`;
export const TimeWrapper = styled.div`
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin: 0px;
  margin-bottom: 8px;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 19px;
  .ui.selection.dropdown .menu > .item {
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    min-width: 74px;
    &:hover {
      background: #6f42f5;
      color: #ffff;
    }
  }
  .ui.selection.dropdown:focus {
    border-color: transparent;
  }
  .time {
    background: transparent;
    border-color: transparent;
    font-family: Lato;
    font-size: 10px;
    line-height: 12px;
  }
  .dropDowns {
    display: flex;
  }
  .icon-column {
    margin-left: 4px;
  }
  .first-column {
    max-width: 78px;
    min-width: 65px;
    margin-right: 2px;
  }
  .second-column {
    max-width: 76px;
    min-width: 65px;
  }
  .ui.grid > .row {
    padding: 0px;
  }
  .ui.selection.dropdown {
    max-width: 72px;
    min-width: 72px;
    padding: 0px 4px;
    .dropdown.icon {
      padding: 0px;
    }
  }
`;

export const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  color: #21303a;
  .ui.selection.dropdown {
    min-width: 118px;
    max-width: 118px;
    max-height: 24px;
    min-height: 24px;
    text-align: center;
    font-size: 8px;
    margin-left: 4px;
  }
  .ui.dropdown > .text {
    text-align: left;
    text-align-last: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 40px;
  }
  .ui.grid > .row {
    padding: 0 !important;
  }
  .row-style {
    margin-bottom: 8px;
  }
  .dtLocation {
    margin-left: 12px;
    width: 85px !important;
    font-size: 8px !important;
  }
`;

export const CustomLocationWrapper = styled.div`
  display: flex;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 19px;
  color: #21303a;
  .ui.selection.dropdown {
    width: 140px;
    height: 30px;
    text-align: left;
    font-size: 10px;
  }
  .ui.dropdown > .text {
    text-align: left;
    text-align-last: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 55px;
  }
  .ui.grid > .row {
    padding: 0 !important;
  }
  .row-style {
    margin-bottom: 8px;
  }
  .dtLocation {
    margin-left: 12px;
    width: 85px !important;
    font-size: 8px !important;
  }
`;

export const TimeSlotWrapper = styled.div`
  .ui.selection.dropdown {
    min-width: 118px;
    max-width: 130px;
    height: 27px;
    font-size: 10px;
    margin-left: 4px;
  }
`;
export const LocationWrapperDT = styled.div`
  display: flex;
  align-items: center;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  line-height: 19px;
  color: #21303a;
  .ui.selection.dropdown {
    min-width: 118px;
    max-width: 118px;
    max-height: 24px;
    min-height: 24px;
    text-align: center;
    font-size: 8px;
    margin-left: 4px;
  }
  .ui.dropdown > .text {
    text-align: left;
    text-align-last: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 40px;
  }
  .ui.grid > .row {
    padding: 0 !important;
  }
  .row-style {
    margin-bottom: 8px;
  }
  .dtLocation {
    margin-left: 5px;
    font-size: 8px !important;
  }
`;

export const LocationListWrapper = styled.div`
  .ui.selection.dropdown {
    min-width: 136px;
    max-width: 136px;
    max-height: 24px;
    min-height: 24px;
    text-align: center;
    font-size: 10px;
  }
  .ui.input {
    min-width: 136px;
    max-height: 24px;
    min-height: 24px;
    text-align: center;
    font-size: 10px;
  }
  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    text-align: center;
    text-align-last: center;
    position: absolute;
    width: 84px;
    height: 12px;
    left: 20px;
    top: 5px;
  }
  .ui.dropdown > .text {
    text-align: left;
    text-align-last: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 104px;
  }
`;

export const CustomLocationListWrapper = styled.div`
  .ui.selection.dropdown {
    width: 140px;
    height: 30px;
    text-align: left;
    font-size: 10px;
    margin-top: 10px;
    padding-left: 4px !important;
  }
  .ui.input {
    width: 140px;
    height: 30px;
    text-align: left;
    font-size: 10px;
  }
  .ui.default.dropdown:not(.button) > .text,
  .ui.dropdown:not(.button) > .default.text {
    text-align: left;
    text-align-last: left;
    position: absolute;
    width: 84px;
    height: 12px;
    left: 20px;
    top: 5px;
  }
  .ui.dropdown > .text {
    text-align: left;
    text-align-last: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 104px;
  }
`;

export const HeaderStatisticsWrapper = styled.div`
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 21px;
  padding-top: 8px;
  .title {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 15px;
  }
  .statistics {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
  }
  .potentialResults {
    color: ${colors.PURPLE};
  }
  .calculatedResult {
    color: #e88024;
  }
  .bars {
    display: flex;
    justify-content: center;
  }
`;

export const ProviderTimeWraper = styled.div`
  .d-flx {
    display: flex;
  }
  .flx-dr-c {
    flex-direction: column;
  }
  .w-100 {
    width: 100%;
  }
  .time-text {
    font-weight: 500 !important;
    font-size: 12px !important;
  }
`;
export const SubstituteWrapper = styled.div`
  padding-left: 24px;
  padding-right: 10px;
  font-family: Lato;
  color: #415766;
  .red {
    color: #e82424;
  }
  .f-w-bold {
    font-weight: bold;
  }
`;
export const SubstituteListWrapper = styled.div`
  .substitute-result-p {
    padding-left: 10px !important;
    padding-top: 10px !important;
    font-weight: bold !important;
  }
  .p-flex {
    display: 'flex';
    flex-direction: 'column';
  }
  .p-pd-l {
    padding-left: 10px;
    font-weight: bold;
  }
  .div-flex {
    flex-grow: 1;
  }
  .pd-l {
    padding-left: 10px;
    color: #e82424;
  }
  .pd-b-10 {
    padding-bottom: 10px;
  }
`;
export const RecurringSSFilterInputWrapper = styled.div`
  padding-left: 21px;
  padding-right: 21px;
  padding-bottom: 21px;
  padding-top: 8px;
  .grid-cls {
    width: 360px;
  }
  .label-title {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #3caf05;
    margin-bottom: 1px;
  }
  .filters-title {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }
  .cursor-cls {
    cursor: pointer;
  }
  input:placeholder {
    color: #000; /* You can use any color value here */
  }
  .ignore {
    font-weight: 900;
  }
  .toggle-rectangle {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ui.toggle.checkbox .box:before,
  .ui.toggle.checkbox label:before {
    background-color: #e82424;
  }
  .ui.toggle.checkbox .box:hover:before,
  .ui.toggle.checkbox label:hover:before {
    background-color: #e82424;
  }

  .ui.toggle.checkbox input:focus:checked ~ label:before {
    background-color: #3caf05 !important;
  }
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: #3caf05 !important;
  }

  .ui.checkbox input:focus ~ .box:before,
  .toggle-cls .ui.checkbox input:focus ~ label:before {
    background-color: #e82424 !important;
  }
`;
