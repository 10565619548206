import React, { useCallback } from 'react';
import { ApolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/react-hooks';
import apolloCache from '../apolloClientCache';
import apolloLink from '../apolloClientLink';
import LoggedInUserInfo from 'authentication-apollo/types/loggedInUserInfo';
import UserCredentials from 'authentication-apollo/types/userCredentials';

export interface Props {
  children: (
    acount: Props['activeAccount'],
    logout: Props['handleLogout']
  ) => React.ReactNode;
  handleLogout: () => Promise<void>;
  acquireToken: () => Promise<string | undefined>;
  activeAccount: LoggedInUserInfo;
}

export default function ApolloWrapper({
  children,
  handleLogout,
  acquireToken,
  activeAccount
}: Props) {
  const env = process.env.REACT_APP_STAGE;

  const loadLoggedInUserCredentials = useCallback(async () => {
    const token = await acquireToken();

    return {
      accessToken: token,
      ...activeAccount
    } as UserCredentials;
  }, [acquireToken, activeAccount]);

  const apolloClient = new ApolloClient({
    cache: apolloCache,
    link: apolloLink(loadLoggedInUserCredentials),
    connectToDevTools: env === 'DEV'
  });
  return (
    <ApolloProvider client={apolloClient}>
      {children(activeAccount, handleLogout)}
    </ApolloProvider>
  );
}
