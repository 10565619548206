import moment from 'moment';

export const getAppointmentsEndDate = startDate => {
  // const endOfCurrentYear = moment(startDate).endOf('year');
  // if (endOfCurrentYear.diff(moment(startDate), 'months') <= 1) {
  //   return endOfCurrentYear.add(1, 'years');
  // }
  return moment('2025-01-04');
};

export const WEEK = ['Sun', 'Mon', 'Tues', 'Weds', 'Thurs', 'Fri', 'Sat'];
export const DAY_INDEX = new Map(
  Object.entries({
    0: 'Sun',
    1: 'Mon',
    2: 'Tues',
    3: 'Weds',
    4: 'Thurs',
    5: 'Fri',
    6: 'Sat'
  })
);
export const DAY_TO_INDEX = new Map(
  Object.entries({
    sun: 0,
    mon: 1,
    tues: 2,
    weds: 3,
    thurs: 4,
    fri: 5,
    sat: 6
  })
);
export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const NO_PREFERENCE_OPTION = {
  id: -1,
  value: 'No Preference'
};

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_AUTH_TAB_PAGE_SIZE = 15;

export const DEFAULT_WAITLIST_TAB_PAGE_SIZE = 15;

export const DEFAULT_SORT_ORDER = [{ name: 'asc' }];
export const DEFAULT_SORT = 'ASC';

export const DEFAULT_FILTER_CLINIC_NAME = 'Torrance';

export const DEFAULT_PHOTO_URL =
  'https://discountdoorhardware.ca/wp-content/uploads/2018/06/profile-placeholder-3.jpg';

export const DEFAULT_DOCTOR_IMAGE =
  'https://image.freepik.com/free-vector/doctor-character-background_1270-84.jpg';

export const DEFAULT_RECOMMENDED_EVALUATION_TYPES = [
  'Neurodevelopmental Eval',
  'Care Plan Meeting'
];

export const DEFAULT_STAFF_FILTER_SIZE = 5;

export const APPOINTMENTS_END_DATE = getAppointmentsEndDate();

export const LOCATION_DEFAULT_COUNTRY = 'United States';

export const DEFAULT_COLOR = '#ffffff';

export const UPDATE_EVENT_OPERATION = 'reschedule';

export const EDIT_EVENT_BEHAVIOR_OPERATION = 'editBehavior';

export const DEFAULT_EDIT_TIME_NOTE = 'Patient Time Adjustment';

export const DEFAULT_DURATION = {
  ADMIN: 30,
  CLIENT: 15,
  ABA: 120
};

export const DEFAULT_TELEHEALTH = {
  PREFIX: 'All ',
  SUFFIX: ' Clinics'
};

export const ABA_ALERT_MESSAGES = {
  timeAlert: `The Observer's start and/or end time must fall within the Provider's
  meeting time.`,
  durationAlert: `The Observer's duration must be minimum 15 mins.`
};

export const FORMAT_CLIENT_DOB = date => {
  const result = moment(date, 'YYYY-MM-DD').format('MM-DD-YYYY');
  return result;
};
export const DRIVE_TIME = 30;
