/* eslint-disable no-constant-condition */
import { yupResolver } from '@hookform/resolvers/yup';
import { ICalendarResource, IRecurringReqData } from 'model/calendar/filters';
import {
  IClient,
  IFormEvent,
  IFullAppointmentType,
  ISmartForm,
  SidebarState
} from 'model/v2';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
  useEffect
} from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { APPOINTMENT_CATEGORY } from 'utils/constants/lists';
import { NEW_SMART_APPOINTMENT_SCHEMA } from 'utils/validators/calendar';
import Content from 'views/components/ui/content';
import { SIDEBAR_ACTIONS, initSmartValues } from '../AppointmentSidebar';
import CarePlanDetails from './CarePlanDetails';
import MetaFieldsForm from './MetaFieldsForm';
import FormEvents from 'pages/MainCalendarPage/FormPhantomEvents/FormPhantomEvents';
import { useClinicDataMap } from 'api/graphql/v2/hooks/clinics';
import CategoryForm from './CategoryFormV2';
import BottomSideBar from 'views/components/ui/sidebar/BottomSideBar';
import {
  mapDTSmartResultGeneration,
  mapABASmartResultGeneration,
  mapSingleApptlSmartResultGeneration,
  _isWelcomeVisit
} from 'utils/mappers/request/smartSchedule';
import {
  GET_ABA_SMART_RESULT_WITH_RANGES,
  GET_DT_SMART_RESULT,
  GET_SINGLE_APPT_SMART_RESULT
} from 'api/graphql/v2/queries/SmartSchedule';
import { Button } from 'lib/ui';
import { getHoursFromTime } from './Client/SessionDuration/ABASessionDuration';
import ClientAvailabilityFilter from './ClientAvailabilityFilter';
import { FEATURES, getFeatureAvailability } from 'utils/featureToggle';
import { SmartWrapper } from './style';
import ProviderFilter from './ProviderFilter';
import NewClientAvailabilityFilter from './ClientAvailabilityFilter/NewClientAvailabilityFilter';
import { useApolloClient } from '@apollo/react-hooks';
import SingleApptResultList from './SmartScheduleResult/SingleApptResultList';
import { getSingleAppointmentTypeIds } from 'utils/mappers/smartSchedule';
import { useRemoveProviderFromCalendar } from '../FormPhantomEvents/CustomPhantomHooks';
import { toast } from 'react-toastify';
import InputClientAvailabilityFilter from './ClientAvailabilityFilter/InputClientAvailabilityFilter';
import RecurringSSResultList from './SmartScheduleResult/RecurringSSResultList';

interface Props {
  setVisible: (val: boolean) => void;
  sidebarState: SidebarState;
  paginationArray: ICalendarResource[];
  setPaginationArray: Dispatch<React.SetStateAction<ICalendarResource[]>>;
  appointmentCategory: string;
  setSmart: Dispatch<SetStateAction<boolean>>;
  setAppointmentCategory: Dispatch<SetStateAction<string>>;
  changeFormHandler: (val: IFormEvent) => void;
  isCustom: boolean;
  smart: boolean;
  currClient: IClient | undefined;
  setCurrClient: Dispatch<SetStateAction<IClient | undefined>>;
  apptTypes: IFullAppointmentType[];
  loadingApptTypes: boolean;
  adminTypes?: IFullAppointmentType[];
  setAction: Dispatch<SetStateAction<string>>;
  parent_Type_ID?: number;
  subType_Id?: number;
}
const SmartScheduling: React.FC<Props> = ({
  setVisible,
  sidebarState,
  paginationArray,
  setPaginationArray,
  appointmentCategory,
  setAppointmentCategory,
  changeFormHandler,
  isCustom,
  smart,
  setSmart,
  currClient,
  setCurrClient,
  apptTypes,
  loadingApptTypes,
  adminTypes,
  setAction,
  parent_Type_ID,
  subType_Id
}: Props) => {
  const apolloClient = useApolloClient();
  const [smartLoading, setSmartLoading] = useState<boolean>(false);
  const [isSingleAppt, setIsSingleAppt] = useState<boolean>();
  const [autoGeneratedResults, setAutoGeneratedResults] = useState<boolean>(
    false
  );

  const [selectedAppointment, setSelectedAppointment] = useState<string>(
    'OT Eval'
  );
  const isAba = useMemo(
    () => appointmentCategory === APPOINTMENT_CATEGORY[0].value,
    [appointmentCategory]
  );
  let isDT = useMemo(
    () => appointmentCategory === APPOINTMENT_CATEGORY[1].value,
    [appointmentCategory]
  );

  const methods = useForm<ISmartForm>({
    defaultValues: React.useMemo(() => {
      const apptTypeTitle = apptTypes.find(
        data => data.id === sidebarState?.event?.parent_Type_ID
      )?.title;
      return initSmartValues(
        sidebarState.event,
        appointmentCategory === APPOINTMENT_CATEGORY[0].value,
        appointmentCategory === APPOINTMENT_CATEGORY[1].value,
        appointmentCategory,
        currClient,
        undefined,
        apptTypeTitle
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      sidebarState.event,
      appointmentCategory,
      currClient,
      parent_Type_ID,
      subType_Id,
      apptTypes
    ]),
    resolver: yupResolver(NEW_SMART_APPOINTMENT_SCHEMA(appointmentCategory)),
    mode: 'all',
    shouldFocusError: true,
    shouldUnregister: false,
    reValidateMode: 'onChange'
  });

  const { handleSubmit, reset } = methods;

  useEffect(() => {
    const apptTypeTitle = apptTypes.find(
      data => data.id === sidebarState?.event?.parent_Type_ID
    )?.title;
    reset(
      initSmartValues(
        sidebarState.event,
        isAba,
        isDT,
        appointmentCategory,
        currClient,
        isSingleAppt,
        apptTypeTitle
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset, sidebarState, isAba, isDT, apptTypes]);

  useEffect(() => {
    if (isSingleAppt)
      methods.setValue('isIgnoreClientProfileAvailability', false);
    else methods.setValue('isIgnoreClientProfileAvailability', true);
  }, [isSingleAppt, methods]);

  useEffect(() => {
    const clientId = methods.getValues('client.id');
    if (!clientId) methods.setValue('clientAvailabilityDayFilter', undefined);
  }, [methods]);

  //const { apptTypes, loadingApptTypes } = useApptTypesWithAdmin();
  const getSmartScheduleQuery = useCallback(() => {
    return isAba ? GET_ABA_SMART_RESULT_WITH_RANGES : GET_DT_SMART_RESULT;
  }, [isAba]);
  const [visibleResults, setVisibleResults] = useState(false);
  const [openingCardsStatus, setOpeningCardsStatus] = useState(false);

  const [smartResults, setSmartResults] = useState<any>();
  const [single, setSingle] = useState<any>(false);
  const { removeAllProvidersFromCalendar } = useRemoveProviderFromCalendar();
  const isReorderFiltrSearchProvider = true;
  const getSmartResultsMapping = useCallback(
    smartData => {
      const resultsData =
        smartData?.DTSmartScheduleOpenings ??
        smartData.ABASmartScheduleOpeningsWithRanges;
      const formValues: any = methods?.getValues('dt');
      let smartResults = {
        clientTimezone: resultsData.clientTimezone,
        bookedDays: resultsData?.bookedDays,
        bookedProviders: formValues?.maximumProviders || 0,
        totalCareplan: formValues?.weeklySessions,
        openingCards: resultsData?.openingCards
      };
      return smartResults;
    },
    [methods]
  );
  const getSingleEventResultsMapping = useCallback(smartData => {
    const resultsData = smartData?.SingleEventSmartScheduleOpenings;
    let smartResults = {
      openingCards: resultsData
    };
    return smartResults;
  }, []);
  const defaultReqData: IRecurringReqData = {
    startDate: undefined,
    weeklySessions: 0,
    data: undefined
  };
  const [reqData, setReqData] = useState<IRecurringReqData>(defaultReqData);
  const onSubmit = handleSubmit(async (data: ISmartForm) => {
    let singleApptIds: any[];
    let weeklySessions = data.dt?.weeklySessions!;
    if (isAba) weeklySessions = 1;
    setReqData(prev => ({
      ...prev,
      startDate: data.startDate,
      weeklySessions: weeklySessions,
      data: data
    }));
    singleApptIds = getSingleAppointmentTypeIds(process.env.REACT_APP_STAGE);
    setSingle(singleApptIds.includes(data.appType));
    const mappedClientAvailabilityDayFilter = data?.clientAvailabilityDayFilter?.map(
      day => {
        const mappedPreferredTimes = day.preferredTimes?.map((time: any) => {
          return {
            from: time.from,
            to: time.to,
            isTelehealth: (time.location & 1) > 0,
            isInClinic: (time.location & 2) > 0,
            isOffsite: (time.location & 4) > 0
          };
        });
        return { ...day, preferredTimes: mappedPreferredTimes };
      }
    );
    data.clientAvailabilityDayFilter = mappedClientAvailabilityDayFilter;
    setSmartLoading(true);
    if (!singleApptIds.includes(data.appType)) {
      removeAllProvidersFromCalendar();
      setPaginationArray([]);
      setSmartResults(undefined);
      const { data: smartResultsData } = await apolloClient.query({
        query: getSmartScheduleQuery(),
        variables: isAba
          ? mapABASmartResultGeneration(data, sidebarState.event)
          : mapDTSmartResultGeneration(data, sidebarState.event),
        fetchPolicy: 'network-only'
      });

      toggleResultsView();
      const mappedSmartResults = getSmartResultsMapping(smartResultsData);

      setSmartResults(mappedSmartResults);
      setAction(SIDEBAR_ACTIONS.POTENIAL_RESULTS);
      setSmartLoading(false);
      setDisplayScroll(false);
    } else {
      removeAllProvidersFromCalendar();
      try {
        const { data: singleApptResultsData } = await apolloClient.query({
          query: GET_SINGLE_APPT_SMART_RESULT,
          variables: mapSingleApptlSmartResultGeneration(data),
          fetchPolicy: 'no-cache'
        });
        toggleResultsView();
        const mappedSmartResults = getSingleEventResultsMapping(
          singleApptResultsData
        );
        setSmartResults(mappedSmartResults);
        setSmartLoading(false);
        setDisplayScroll(false);
      } catch (error) {
        setSmartLoading(false);
        toast.error('Something went wrong !');
      }
    }
  });
  const toggleResultsView = useCallback(() => {
    setVisibleResults(!visibleResults);
  }, [visibleResults]);

  const {
    providersMap,
    clinicsMap,
    //loadingClinics,
    errorClinics
  } = useClinicDataMap(sidebarState.action);
  const nonEvalApptTypes = useMemo(() => {
    return apptTypes;
  }, [apptTypes]);
  const [displayScroll, setDisplayScroll] = useState(true);
  useEffect(() => {
    if (autoGeneratedResults) {
      var elements = document.getElementsByClassName('generate-results');
      if (elements.length > 0) {
        const element = elements[0] as HTMLElement;
        element.click();
      }
    }
  }, [autoGeneratedResults]);
  const { appType } = useWatch({
    name: ['appType'],
    control: methods.control
  });
  return (
    <>
      {smartResults && !single ? (
        <>
          <RecurringSSResultList
            setVisible={setVisible}
            visibleResults={visibleResults}
            action={sidebarState.action}
            providersMap={providersMap}
            apptTypes={apptTypes}
            isAba={isAba}
            appointmentTypeId={methods.getValues('appType') || 0}
            appointmentSubTypeId={methods.getValues('appSubType')}
            client={methods.getValues('client')!}
            paymentMethod={methods.getValues('paymentMethod')!}
            smartResults={smartResults}
            setSmartResults={setSmartResults}
            paginationArray={paginationArray}
            setPaginationArray={setPaginationArray}
            hasSingleDT={single}
            adminTypes={adminTypes}
            reqData={reqData}
            setReqData={setReqData}
            setOpeningCardsStatus={setOpeningCardsStatus}
            openingCardsStatus={openingCardsStatus}
            smartLoading={smartLoading}
          />
        </>
      ) : (
        <>
          <Content
            loading={loadingApptTypes}
            data={clinicsMap}
            error={errorClinics}
          >
            {() => (
              <BottomSideBar
                visible={visibleResults}
                displayScroll={displayScroll}
                pageComponent={
                  <FormProvider {...methods}>
                    <FormEvents
                      appTypes={nonEvalApptTypes}
                      providersMap={providersMap}
                      formAction={sidebarState.action}
                      paginationArray={paginationArray}
                      setPaginationArray={setPaginationArray}
                      isReorderFiltrSearchProvider={
                        isReorderFiltrSearchProvider
                      }
                    />
                    <SmartWrapper>
                      <CategoryForm
                        isCustom={isCustom}
                        appointmentCategory={appointmentCategory}
                        setSmart={setSmart}
                        smart={smart}
                        setAppointmentCategory={setAppointmentCategory}
                        sidebarState={sidebarState}
                        changeFormHandler={changeFormHandler}
                        setCurrClient={setCurrClient}
                      />
                      <MetaFieldsForm />
                      <CarePlanDetails
                        appointmentCategory={appointmentCategory}
                        apptTypes={apptTypes}
                        smart={smart}
                        setIsSingleAppt={setIsSingleAppt}
                        setSelectedAppointment={setSelectedAppointment}
                        parent_Type_ID={parent_Type_ID}
                        subType_Id={subType_Id}
                        sidebarState={sidebarState}
                      />
                      {!isSingleAppt ? (
                        <>
                          {getFeatureAvailability(
                            FEATURES.NEW_CLIENT_AVAILABILITY_FILTER
                          ) && (
                            // We have created a new client availability filter for DT Appointments as per the latest designs
                            <InputClientAvailabilityFilter
                              setAutoGeneratedResults={setAutoGeneratedResults}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {/** @todo this based on CORT-8264, so we have disabled the old form and the main design may be changed again*/}
                          {getFeatureAvailability(
                            FEATURES.CLIENT_AVAILABILITY_FILTER
                          ) && <ClientAvailabilityFilter />}
                          {getFeatureAvailability(
                            FEATURES.NEW_CLIENT_AVAILABILITY_FILTER
                          ) && (
                            <NewClientAvailabilityFilter
                              isSingleAppt={isSingleAppt}
                            />
                          )}
                          {getFeatureAvailability(FEATURES.PROVIDER_FILTER) && (
                            <ProviderFilter
                              isSingleAppt={isSingleAppt}
                              disabled={_isWelcomeVisit(appType)}
                            />
                          )}
                        </>
                      )}
                    </SmartWrapper>
                    <Button
                      className="generate-results"
                      type="submit"
                      onClick={onSubmit}
                      loading={smartLoading}
                      disabled={smartLoading}
                    >
                      Generate Results
                    </Button>
                  </FormProvider>
                }
                content={
                  //@todo update to be used with DT
                  <>
                    <SingleApptResultList
                      setDisplayScroll={setDisplayScroll}
                      setVisible={setVisible}
                      toggleResultsView={toggleResultsView}
                      visibleResults={visibleResults}
                      action={sidebarState.action}
                      providersMap={providersMap}
                      apptTypes={apptTypes}
                      isAba={isAba}
                      appointmentTypeId={methods.getValues('appType') || 0}
                      appointmentSubTypeId={methods.getValues('appSubType')}
                      minHours={getHoursFromTime(
                        methods.getValues('aba')?.sessionDuration?.min!
                      )}
                      client={methods.getValues('client')!}
                      paymentMethod={methods.getValues('paymentMethod')!}
                      smartResults={smartResults}
                      paginationArray={paginationArray}
                      setPaginationArray={setPaginationArray}
                      isDT={isDT}
                      hasSingleDT={single}
                      setCurrClient={setCurrClient}
                      currClient={currClient}
                      selectedAppointment={selectedAppointment}
                      setSmartResults={setSmartResults}
                    />
                  </>
                }
              ></BottomSideBar>
            )}
          </Content>
        </>
      )}
    </>
  );
};
export default React.memo(SmartScheduling);
