import { AutoComplete } from 'antd';
import { FormError } from 'api/sharedComponents/reactHookFormComponents/error';
import React, { useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { STYLE_CLASS } from 'utils/constants/appointmentsTypes';

interface props {
  isSelected: boolean;
  value: string;
  name: string;
  errorCheck: boolean;
  valuesToBeTriggered?: string[];
  setIsSelected: React.Dispatch<React.SetStateAction<boolean>>;
}
const WeeklyHoursAutoComplete = ({
  isSelected,
  value,
  name,
  errorCheck,
  valuesToBeTriggered,
  setIsSelected
}: props) => {
  const { setValue, trigger, errors } = useFormContext();

  const [showAllOptions, setShowAllOptions] = useState<boolean>(true);

  const weeklyOptions = useMemo(() => {
    const options = [];
    for (let i = 0; i <= 40; i++) {
      options.push(`${i.toString().padStart(2, '0')}:00`);
    }
    return options;
  }, []);

  const onSelected = useCallback(() => {
    trigger([name]);
    setIsSelected(true);
    setShowAllOptions(true);
  }, [trigger]);

  const onChanged = useCallback(
    (value: string) => {
      setShowAllOptions(false);
      const lastChar = value[value.length - 1];
      if (isNaN(parseInt(lastChar, 10)) && lastChar !== ':') {
        setValue(name, value.substr(0, value.length - 1), {
          shouldValidate: true
        });
      } else {
        if (value.length > 2 && !value?.includes(':')) {
          setValue(name, `${value.substr(0, 2)}:${value.substr(2)}`, {
            shouldValidate: true
          });
        } else {
          setValue(name, value, { shouldValidate: true });
        }
      }
      if (valuesToBeTriggered?.length) trigger(valuesToBeTriggered);
    },
    [setValue, trigger]
  );

  return (
    <div>
      <AutoComplete
        style={{ width: 100 }}
        dataSource={weeklyOptions}
        value={value}
        placeholder={'01:00'}
        maxLength={5}
        className={
          isSelected
            ? errorCheck
              ? STYLE_CLASS.INDEXED_SELECTED_ERROR_STYLE
              : STYLE_CLASS.INDEXED_SELECTED_STYLE
            : STYLE_CLASS.INDEXED_UNSELECTED_STYLE
        }
        onSelect={onSelected}
        filterOption={(input: any, option: any) => {
          return (
            showAllOptions ||
            option.value
              .toLowerCase()
              .trim()
              .indexOf(input.toLowerCase().trim()) >= 0
          );
        }}
        getPopupContainer={trigger => trigger.parentElement}
        onChange={onChanged}
        onFocus={() => setShowAllOptions(true)}
      />
      <FormError name={name} errors={errors} />
    </div>
  );
};

export default React.memo(WeeklyHoursAutoComplete);
