import {
  IFullAppointmentType,
  IMappedEvent,
  ISmartResultItemForm,
  SingleSmartResultInterface
} from 'model/v2';
import moment, { Moment } from 'moment';
import {
  DRIVE_TIME_APPT_ID,
  DT_SUBSTITUTE_SUB_APPT_IDS,
  MAKEUP_SESSION_APPT_IDS,
  MAKEUP_SESSION_SUB_APPT_IDS,
  SUBSTITUTE_APPT_IDS
} from 'utils/constants/appointmentsTypes';
import {
  CLINIC_PROVIDERS,
  EVENT_OCCURENCE,
  LOCATION_TYPE_LIST
} from 'utils/constants/lists';
import { formatMinToHHmmTime } from 'utils/format';
import { LocationType } from 'views/components/icons/eventIcons/locationIcon';

const adjustTimeWithDate = (momentObject: Moment, inputTime: string) => {
  const clonedMomentObject = momentObject.clone();
  const splitted = inputTime.split(':');
  clonedMomentObject.set({
    hour: (splitted[0] as unknown) as number,
    minute: (splitted[1] as unknown) as number,
    seconds: 0,
    milliseconds: 0
  });
  return clonedMomentObject;
};
const getEventDate = (date: string, time: string) => {
  let adjustedStartDate = moment(date);
  adjustedStartDate = adjustTimeWithDate(adjustedStartDate, time);
  return adjustedStartDate.toISOString();
};

const getLocation = (data: ISmartResultItemForm) => {
  if (data.isInClinic) return LOCATION_TYPE_LIST[0].id;
  else if (data.isOffsite) return LOCATION_TYPE_LIST[1].id;
  else if (data.isTelehealth) return LOCATION_TYPE_LIST[2].id;
  else return undefined;
};

const getRecurrencePattern = (endDate: string) => {
  const recurringEvery = EVENT_OCCURENCE[0].value as number;
  const recurringUntil = moment(endDate)
    .endOf('day')
    .toISOString();
  return {
    recurringEvery,
    recurringUntil
  };
};

const getRecurrencePatternForSingleAppoitments = () => {
  const recurringEvery = 0;
  const recurringUntil = null;
  return {
    recurringEvery,
    recurringUntil
  };
};

const getDuration = (startDate: any, endDate: any) => {
  const startObject = moment(startDate);
  const endObject = moment(endDate);
  return endObject.diff(startObject, 'minutes');
};

const getSmartLocation = (data: SingleSmartResultInterface) => {
  if (data.isInClinic) return LOCATION_TYPE_LIST[0].id;
  else if (data.isOffsite) return LOCATION_TYPE_LIST[1].id;
  else if (data.isTelehealth) return LOCATION_TYPE_LIST[2].id;
  else return undefined;
};
export const prepareSmartEventForSubmission = (
  data: ISmartResultItemForm,
  appointmentSubTypeId?: number
): IMappedEvent => {
  const startDate = getEventDate(
    moment(data.startTime).format('YYYY-MM-DD'),
    formatMinToHHmmTime(data.startTimeForm)
  );
  const endDate = getEventDate(
    moment(data.startTime).format('YYYY-MM-DD'),
    formatMinToHHmmTime(data.endTimeForm)
  );
  const duration = getDuration(startDate, endDate);
  const locationType = getLocation(data) as LocationType;
  const clinicPreference = CLINIC_PROVIDERS[1].id;
  const recurrencePattern = getRecurrencePattern(data.endDate);
  return {
    client: {
      id: data.client.id
    },
    provider: {
      id: data.provider.id
    },
    clinic: {
      id: data.clinic?.id
    },
    appointmentType: {
      id: appointmentSubTypeId
        ? appointmentSubTypeId
        : data.appointmentType?.id
        ? data.appointmentType.id
        : data.apptTypeId
    },

    room: data.isInClinic ? { id: data.room! } : undefined,
    startDate,
    endDate,
    duration,
    locationType,
    locationCategory: data.isOffsite ? data.locationCategory! : undefined,
    clinicPreference,
    paymentMethod: data.paymentMethod,
    address: data.isOffsite ? data.address! : undefined,
    recurrencePattern,
    note: undefined,
    telehealthLink: data.isTelehealth ? data.telehealthLink : undefined,
    isPendingConfirmation: data.isInVisible
  };
};
export const prepareSmartEvalEventForSubmission = (
  data: any,
  appointmentSubTypeId?: number
): IMappedEvent => {
  const startDate = data.startDate;
  const endDate = data.endDate;
  const duration = getDuration(startDate, endDate);
  const locationType = getLocation(data) as LocationType;
  const clinicPreference = CLINIC_PROVIDERS[1].id;
  const recurrencePattern = getRecurrencePatternForSingleAppoitments();

  return {
    client: {
      id: data.client.id
    },
    provider: {
      id: data.provider.id
    },
    clinic: {
      id: data.clinic?.id
    },
    appointmentType: {
      id: appointmentSubTypeId ? appointmentSubTypeId : data.appointmentType.id
    },

    room: data.isInClinic ? { id: data.room! } : undefined,
    startDate,
    endDate,
    duration,
    locationType,
    locationCategory: data.isOffsite ? data.locationCategory! : undefined,
    clinicPreference,
    paymentMethod: data.paymentMethod,
    address: data.isOffsite ? { id: data.address! } : undefined,
    recurrencePattern,
    note: undefined,
    telehealthLink: data.isTelehealth ? data.telehealthLink : undefined,
    isPendingConfirmation: data.isInVisible
  };
};

export const getOrganisedSmartEvent = (
  data: any,
  appointmentTypeId: number,
  appointmentSubTypeId?: number
): IMappedEvent => {
  const startDate = data.startTime;
  const endDate = data.endTime;
  const duration = getDuration(startDate, endDate);
  const locationType = getSmartLocation(data) as LocationType;
  const clinicPreference = CLINIC_PROVIDERS[1].id;
  const recurrencePattern = getRecurrencePatternForSingleAppoitments();

  return {
    client: {
      id: data.client.id
    },
    provider: {
      id: data.provider.id
    },
    clinic: {
      id: data.clinic?.id
    },
    appointmentType: {
      id: appointmentSubTypeId ? appointmentSubTypeId : appointmentTypeId
    },

    room: data.isInClinic ? { id: data.room! } : undefined,
    startDate,
    endDate,
    duration,
    locationType,
    locationCategory: data.isOffsite ? data.locationCategory! : undefined,
    clinicPreference,
    paymentMethod: data.paymentMethod,
    address: data.isOffsite ? data.address! : undefined,
    recurrencePattern,
    note: undefined,
    telehealthLink: data.isTelehealth ? data.telehealthLink : undefined,
    isPendingConfirmation: data.isInVisible
  };
};
export const prepareSmartDriveTimeEventForSubmission = (
  data: ISmartResultItemForm
): IMappedEvent => {
  const driveEndTime = getEventDate(
    data.startDate,
    formatMinToHHmmTime(data.startTimeForm)
  );
  const driveStartTime = moment(driveEndTime)
    .subtract(data.driveTime, 'minutes')
    .toISOString();
  const clinicPreference = CLINIC_PROVIDERS[1].id;
  const recurrencePattern = getRecurrencePattern(data.endDate);

  return {
    provider: {
      id: data.provider.id
    },
    clinic: {
      id: data.clinic?.id
    },
    appointmentType: {
      id: DRIVE_TIME_APPT_ID[process.env.REACT_APP_STAGE!]
    },
    startDate: driveStartTime,
    endDate: driveEndTime,
    duration: data.driveTime,
    clinicPreference,
    recurrencePattern,
    isPendingConfirmation: false
  } as IMappedEvent;
};

export const getSubstituteMakeUpPayload = (
  reqEvent: any,
  event: any,
  appTypesMap: Map<number, IFullAppointmentType>
) => {
  console.log('[getSubstituteMakeUpPayload] reqevent', reqEvent);
  console.log('[getSubstituteMakeUpPayload] event', event, appTypesMap);
  console.log('[getSubstituteMakeUpPayload] apptTypes', appTypesMap);
  const makeUpSessionApptId = getSubstituteApptId(
    event.appointmentType.eventType.name === 'DT' ||
      event.appointmentType.eventType.name === 'MED'
      ? event.appointmentType.parent.id
      : event.appointmentType.id,
    true,
    appTypesMap,
    event
  );

  const parsedPreviousEvent = JSON.parse(JSON.stringify(event));
  const mappedEvent = {
    id: reqEvent?.id,
    client: reqEvent?.client
      ? {
          id: reqEvent?.client?.id
        }
      : null,
    provider: {
      id: reqEvent?.provider?.id
    },
    clinic: {
      id:
        parsedPreviousEvent?.clinic?.id ||
        parsedPreviousEvent?.client?.clinic?.id
    },
    appointmentType: {
      id: makeUpSessionApptId
    },
    room:
      reqEvent?.locationType === LOCATION_TYPE_LIST[0].id && reqEvent?.room
        ? {
            id: parsedPreviousEvent?.room.id
          }
        : null,
    startDate: reqEvent?.startTime,
    endDate: reqEvent?.endTime,
    duration: moment
      .duration(moment(reqEvent?.endTime!).diff(moment(reqEvent?.startTime!)))
      .asMinutes(),
    locationType: parsedPreviousEvent?.locationType,
    locationCategory: parsedPreviousEvent?.locationCategory,
    clinicPreference: parsedPreviousEvent?.clinicPreference,
    paymentMethod: parsedPreviousEvent?.paymentMethod,
    address:
      parsedPreviousEvent?.locationType === LOCATION_TYPE_LIST[1].id
        ? parsedPreviousEvent?.address
        : null,
    note: reqEvent?.note?.note
      ? {
          id: reqEvent?.note?.id,
          note: reqEvent?.note?.note || ''
        }
      : null,
    telehealthLink: reqEvent?.isTelehealth
      ? reqEvent.telehealthLink
      : undefined,
    abaObserverList: reqEvent?.abaObserversList,
    isPendingConfirmation: reqEvent?.isInVisible
  };
  return mappedEvent;
};

export const getSubstituteApptId = (
  eventApptId: number,
  makeupSession: boolean,
  appTypesMap: Map<number, IFullAppointmentType>,
  event?: any
) => {
  let envSubstitueApptId =
    SUBSTITUTE_APPT_IDS[process.env.REACT_APP_STAGE!][eventApptId];
  if (makeupSession) {
    envSubstitueApptId =
      MAKEUP_SESSION_APPT_IDS[process.env.REACT_APP_STAGE!][eventApptId];
  }
  const apptId = envSubstitueApptId ? envSubstitueApptId : eventApptId;
  const appointmentType = appTypesMap.get(apptId)!;
  const appointmentSubTypeId = event?.appointmentType?.parent?.id
    ? event?.appointmentType?.id
    : undefined;
  let substituteApptId = event?.appointmentType?.parent?.id
    ? event?.appointmentType?.id
    : apptId;
  let envSubstitueSubApptId =
    DT_SUBSTITUTE_SUB_APPT_IDS[process.env.REACT_APP_STAGE!][
      appointmentSubTypeId
    ];
  if (makeupSession) {
    envSubstitueSubApptId =
      MAKEUP_SESSION_SUB_APPT_IDS[process.env.REACT_APP_STAGE!][
        appointmentSubTypeId
      ];
  }
  appointmentType.appointmentSubTypes?.map(subtype => {
    if (subtype.id! === envSubstitueSubApptId) {
      substituteApptId = subtype.id;
    }
    return;
  });
  return substituteApptId;
};
